/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: {
    ...defaultInitialState,
    keepTokenConnection: false,
    stored: true,
    confirming: false,
    confirmed: null,
    confirmError: null,
    confirmSuccess: null,
    sentConfirmation: false,
    sendingConfirmation: false,
    sendConfirmationError: null,
    listMode: "card",
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    setListMode: (state, action) => {
      if (["card", "list"].includes(action.payload))
        state.listMode = action.payload;
    },
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    setVisibility: (state, action) => {
      state.settingVisibility = true;
      state.visibilityError = null;
    },
    setVisibilitySuccess: (state, action) => {
      state.settingVisibility = false;
      state.visibilityError = null;
      defaultListSuccess(state, action);
    },
    setVisibilityFail: (state, action) => {
      state.settingVisibility = false;
      state.visibilityError = action.payload;
    },
    toggleTokenVisibility: defaultUpdate,
    toggleTokenVisibilitySuccess: defaultUpdateSuccess,
    toggleTokenVisibilityFail: defaultUpdateFail,
  },
});
export const {
  list: listTokens,
  setListMode: setTokensListMode,
  show: showToken,
  update: updateToken,
  setVisibility: setTokensVisibility,
  toggleTokenVisibility,
} = tokensSlice.actions;

export const actions = tokensSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const tokens = (state) =>
  state.tokens.idsList.map((id) => state.tokens.dictionary[id]);
export const token = (state) =>
  state.tokens.showId && state.tokens.dictionary[state.tokens.showId];
export const tokenAttributes = (state) => token(state)?.attributes;
export const tokenRelationships = (state) => token(state)?.relationships;
export const tokenId = (state) => state.tokens.showId;

export const getToken = createSelector(
  (state) => state.tokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getTokenAttributes = createSelector(
  (state) => state.tokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getTokenRelationships = createSelector(
  (state) => state.tokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export default tokensSlice.reducer;
