import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { getWalletByAddress } from "../app/wallets";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Collapse,
  Input,
  Row,
  UncontrolledAccordion,
} from "reactstrap";
import TokenCard from "../components/TokenCard";
import ContractAccordionItem from "../components/ContractAccordionItem";
import ContractCard from "../components/ContractCard";
import { Link } from "react-router-dom";
import { listTokens } from "../app/tokens";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import { featuredContractIds, walletContractIds } from "../app/contracts";
import Carousel from "react-multi-carousel";

const WalletDashboard = () => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const tokenListMode = useSelector((state) => state.tokens.listMode);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const currentWalletId = useSelector((state) => state.wallets.currentWalletId);
  const tokens = useSelector((state) => state.tokens.idsList);
  const featuredContracts = useSelector(featuredContractIds);
  const contracts = useSelector(walletContractIds);
  const [listFilter, setListFilter] = useState("visible");

  const wallet = useSelector((state) => getWalletByAddress(state, account));
  // console.log(wallet);

  const handleSearchQuery = (query) => {
    setSearchQuery(query);
    dispatch(listTokens({ search: query }));
  };

  useEffect(() => {
    if (!account || !wallet) return;
    const intervalId = setInterval(() => {
      setSearchQuery((query) => {
        dispatch(listTokens({ search: query, filter: listFilter }));
        return query;
      });
    }, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, account, wallet]);

  return (
    <div className="WalletDashboard">
      <div className="container">
        {account && currentWalletId && (
          <>
            {tokenListMode === "card" && (
              <>
                <h1>Featured</h1>
                <div
                  style={{
                    marginLeft: "calc(-0.5* var(--bs-gutter-x))",
                    marginRight: "calc(-0.5* var(--bs-gutter-x))",
                  }}
                  className="mb-4"
                >
                  <Carousel
                    responsive={{
                      mobile: { breakpoint: { max: 8000, min: 0 }, items: 2 },
                    }}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    arrows={false}
                    autoPlay={false}
                    infinite={false}
                    slidesToSlide={1}
                    sliderClass="react-multi-carousel-track mb-3"
                  >
                    {featuredContracts.map((contract) => (
                      <div
                        key={contract}
                        style={{
                          paddingLeft: "calc(var(--bs-gutter-x)* 0.5)",
                          paddingRight: "calc(var(--bs-gutter-x)* 0.5)",
                        }}
                      >
                        <ContractCard contractId={contract} />
                      </div>
                    ))}
                  </Carousel>
                </div>
                {/* <h1>Your collections</h1>
              <Row>
                {contracts.map((contract) => (
                  <Col xs={4} md={2} key={contract}>
                    <ContractCard contractId={contract} />
                  </Col>
                ))}
              </Row> */}
                <div className="d-flex my-4">
                  <h1 className="align-self-start text-muted">Owned by you</h1>
                </div>
                <div className="my-4">
                  <div className="d-flex justify-content-end">
                    <p className="align-self-end text-muted">
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(listTokens({ filter: "visible" }));
                          setListFilter("visible");
                        }}
                        className={`px-3 py-1 rounded-pill ms-2 ${
                          listFilter === "visible" &&
                          "border border-primary border-1"
                        }`}
                      >
                        Selected
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(listTokens({ filter: "featured" }));
                          setListFilter("featured");
                        }}
                        className={`px-3 py-1 rounded-pill ms-2 ${
                          listFilter === "featured" &&
                          "border border-primary border-1"
                        }`}
                      >
                        Featured
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          dispatch(listTokens({}));
                          setListFilter("all");
                        }}
                        className={`px-3 py-1 rounded-pill ms-2 ${
                          listFilter === "all" &&
                          "border border-primary border-1"
                        }`}
                      >
                        All
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsSearchVisible(!isSearchVisible);
                        }}
                        className={`px-3 py-1 rounded-pill ms-2 ${
                          isSearchVisible && "border border-primary border-1"
                        }`}
                      >
                        <FaSearch className="" />
                      </span>
                    </p>
                  </div>
                  <Collapse isOpen={isSearchVisible}>
                    <Input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => handleSearchQuery(e.target.value)}
                      style={{ backgroundColor: "transparent", width: "50%" }}
                      className="w-100"
                    />
                  </Collapse>
                </div>

                <Row>
                  {tokens.map((token) => (
                    <Col xs={4} md={3} key={token}>
                      <TokenCard tokenId={token} imgOnly />
                    </Col>
                  ))}
                </Row>
              </>
            )}
            {tokenListMode === "list" && (
              <UncontrolledAccordion
                defaultOpen={contracts?.length > 0 ? [contracts[0].id] : []}
                stayOpen
              >
                {contracts.map((contractId) => (
                  <ContractAccordionItem
                    key={contractId}
                    contractId={contractId}
                  />
                ))}
              </UncontrolledAccordion>
            )}
          </>
        )}
        <Link to="/settings">
          <Button className="mt-4 rounded-pill w-100" size="lg">
            Continue
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default WalletDashboard;
