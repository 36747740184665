import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { device, disconnectDevice } from "../app/devices";
import { Button, Col, Container, Row } from "reactstrap";
import { updateDevice } from "../app/devices";
import { useNavigate } from "react-router-dom";
import logo from "../assets/icons/Samsung_wordmark_white.svg";

const TvDeviceSettings = () => {
  const navigate = useNavigate();
  const deviceData = useSelector(device);
  const dispatch = useDispatch();
  const [transitionType, setTransitionType] = useState(
    deviceData?.attributes?.transition_type || "fade"
  );
  const [timing, setTiming] = useState(deviceData?.attributes?.timing || 60);
  const [showInfos, setShowInfos] = useState(
    deviceData?.attributes?.show_info || false
  );

  // console.log("deviceData", deviceData);
  const selectableElements = [
    "button-save",
    "button-fade",
    "button-swipe",
    "button-decrease",
    "button-increase",
    "button-yes",
    "button-no",
    "button-disconnect",
  ];
  const [selectedElement, setSelectedElement] = useState(selectableElements[0]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        ["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
          e.key
        ) > -1
      ) {
        e.preventDefault();
      }
      if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
        setSelectedElement((elem) => {
          let next = selectableElements.indexOf(elem) - 1;
          if (next < 0) {
            next = selectableElements.length - 1;
          }
          return selectableElements[next];
        });
      }
      if (e.key === "ArrowDown" || e.key === "ArrowRight") {
        setSelectedElement((elem) => {
          let next = selectableElements.indexOf(elem) + 1;
          if (next >= selectableElements.length) {
            next = 0;
          }
          return selectableElements[next];
        });
      }

      if (e.key === "Enter") {
        setSelectedElement((selectedEl) => {
          const [type, id] = selectedEl.split("-");
          if (type === "button" && id === "save") {
            saveSettings();
          }
          if (type === "button" && id === "back") {
            navigate(`/tv/wallet`);
          }
          if (type === "button" && id === "fade") {
            setTransitionType("fade");
          }
          if (type === "button" && id === "swipe") {
            setTransitionType("swipe");
          }
          if (type === "button" && id === "yes") {
            setShowInfos(true);
          }
          if (type === "button" && id === "no") {
            setShowInfos(false);
          }
          if (type === "button" && id === "decrease") {
            if (parseInt(timing) > 1) {
              setTiming((tim) => parseInt(tim) - 1);
            }
          }
          if (type === "button" && id === "increase") {
            setTiming((tim) => parseInt(tim) + 1);
          }

          if (type === "button" && id === "disconnect") {
            dispatch(disconnectDevice({ uid: duid }));
          }

          return selectableElements[selectableElements.indexOf(selectedEl)];
        });
      }

      if (e.key === "Backspace" || e.key === "XF86Back") {
        navigate(`/tv/wallet`);
      }

      // console.log(e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableElements]);

  // useEffect(() => {
  //   if (!selectedElement) return;
  //   console.log("scrolling to", selectedElement);
  //   let selectedId = `selector-${selectedElement}`;
  //   document.getElementById(selectedId)?.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //   });
  // }, [selectedElement]);

  // useEffect(() => {
  //   if (!deviceData && deviceId) {
  //     dispatch(showDevice({ id: deviceId }));
  //   }
  // }, [deviceId, deviceData, dispatch]);

  // console.log("selectedElement", selectedElement);
  const duid = localStorage.getItem("duid");

  const saveSettings = () => {
    dispatch(
      updateDevice({
        id: deviceData.id,
        uid: duid,
        transition_type: transitionType,
        timing,
        show_info: showInfos,
        callbackSuccess: () => {
          navigate("/tv/wallet");
        },
      })
    );
  };

  return (
    <div className="tv-main-wrapper">
      {deviceData && (
        <>
          <Container fluid>
            <Row className="mb-48">
              <Col xs={6}>
                <h1 className="mb-0">
                  <img
                    width="7052"
                    height="1080"
                    alt="SAMSUNG"
                    src={logo}
                    style={{ height: "1.5625vw", width: "auto" }}
                  />
                </h1>
                <span className="h1 mt-0 text-muted fs-32">
                  <strong>NFT Gallery</strong>
                </span>
              </Col>
              <Col xs={6} className="text-end">
                <Button
                  color={selectedElement === "button-save" ? "primary" : "dark"}
                  onClick={() => saveSettings()}
                  size="lg"
                  className="rounded-pill"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Container>
          <div className="d-flex justify-content-center">
            <div className="my-3 w-50">
              <h2 className="fs-64">Transition</h2>
              <Row className="my-24">
                <Col>
                  <Button
                    color={
                      selectedElement === "button-fade"
                        ? "primary"
                        : transitionType === "fade"
                        ? "secondary"
                        : "dark"
                    }
                    onClick={() => setTransitionType("fade")}
                    size="lg"
                    className={`rounded-pill w-100 ${
                      selectedElement === "button-fade"
                        ? "selected-setting"
                        : ""
                    }`}
                  >
                    Fade
                  </Button>
                </Col>

                {/* <Col>
                <Button
                  color={transitionType === "blur" ? "primary" : "dark"}
                  onClick={() => setTransitionType("blur")}
                  size="lg"
                  className="rounded-pill w-100"
                >
                  Blur
                </Button>
              </Col> */}
                <Col>
                  <Button
                    color={
                      selectedElement === "button-swipe"
                        ? "primary"
                        : transitionType === "swipe"
                        ? "secondary"
                        : "dark"
                    }
                    onClick={() => setTransitionType("swipe")}
                    size="lg"
                    className="rounded-pill w-100"
                  >
                    Swipe
                  </Button>
                </Col>
              </Row>

              <h2 className="mt-48 fs-56">Timing</h2>
              <div className="d-flex align-items-center mb-5">
                <Button
                  color={
                    selectedElement === "button-decrease" ? "primary" : "dark"
                  }
                  onClick={() =>
                    setTiming((tim) => {
                      if (parseInt(tim) > 1) {
                        return parseInt(tim) - 1;
                      }
                    })
                  }
                  size="lg"
                  className="rounded-pill w-100"
                >
                  -
                </Button>
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <h2 className="h-100 fs-56">{timing}s</h2>
                </div>

                <Button
                  color={
                    selectedElement === "button-increase" ? "primary" : "dark"
                  }
                  onClick={() => setTiming((tim) => parseInt(tim) + 1)}
                  size="lg"
                  className="rounded-pill w-100 mx-1"
                >
                  +
                </Button>
              </div>

              <h2 className="mt-24 fs-56">Show infos on screen</h2>
              <Row className="my-24">
                <Col>
                  <Button
                    onClick={() => setShowInfos(true)}
                    size="lg"
                    className="rounded-pill w-100"
                    color={
                      selectedElement === "button-yes"
                        ? "primary"
                        : showInfos
                        ? "secondary"
                        : "dark"
                    }
                  >
                    Yes
                  </Button>
                </Col>

                <Col>
                  <Button
                    onClick={() => setShowInfos(false)}
                    size="lg"
                    className="rounded-pill w-100"
                    color={
                      selectedElement === "button-no"
                        ? "primary"
                        : !showInfos
                        ? "secondary"
                        : "dark"
                    }
                  >
                    No
                  </Button>
                </Col>
              </Row>

              <hr className="my-48" />
              <p>
                Device ID: <strong>{duid}</strong>
              </p>
              <p>
                Code: <strong>{deviceData?.attributes?.code}</strong>
              </p>
              <p>
                Wallet:{" "}
                <strong>{deviceData?.attributes?.wallet_address}</strong>
              </p>
              <div className="my-5 d-flex justify-content-center">
                <Button
                  color={
                    selectedElement === "button-disconnect"
                      ? "primary"
                      : "secondary"
                  }
                  onClick={() => {
                    dispatch(disconnectDevice({ uid: duid }));
                  }}
                  size="lg"
                  className="rounded-pill w-100 mt-48"
                >
                  Disconnect your wallet
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TvDeviceSettings;
