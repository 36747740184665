/** ----- apiSwaggerRequest.js -----
 *  File: apiSwaggerRequest.js
 *
 *  Purpose: providing helper functions to perform API requests to ease out
 *  error handling through swagger client
 *----------------------------- */
import Swagger from "swagger-client";
import Cookies from "universal-cookie";
const cookieHandler = new Cookies();

/**
 * The swagger client. We make this call to load the swagger json
 */
let cachedClient = null;
const swagger = () => {
  const REACT_APP_API_SERVER = process.env.REACT_APP_API_SERVER;

  if (cachedClient) {
    return cachedClient;
  }
  const url = `${REACT_APP_API_SERVER}/api-docs/v1/swagger.yaml`;
  cachedClient = Swagger({
    url,
  });
  return cachedClient;
};

/**
 * Request interceptor used to add headers to the request
 * @param {Object} headers the headers to apply
 * @returns a request object
 */
export const applyHeaders = (headers) => (req) => {
  const accessToken = cookieHandler.get("access-token");
  const uid = cookieHandler.get("uid");
  const code = cookieHandler.get("code");
  const newHeaders = {
    ...headers,
    "Content-Type": "application/json",
    "access-token": accessToken,
    uid: uid,
    code: code,
  };
  if (newHeaders) {
    Object.keys(newHeaders).map(
      (headerKey) => (req.headers[headerKey] = newHeaders[headerKey])
    );
  }
  return req;
};

export const applyDeviceHeaders = (headers) => (req) => {
  const newHeaders = {
    ...headers,
    "Content-Type": "application/json",
  };
  if (newHeaders) {
    Object.keys(newHeaders).map(
      (headerKey) => (req.headers[headerKey] = newHeaders[headerKey])
    );
  }
  return req;
};

export default swagger;
