import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { device, showDevice } from "../app/devices";
import { getWalletAttributes } from "../app/wallets";
import { Button, Input, Row, Col } from "reactstrap";
import { updateDevice } from "../app/devices";
import { useNavigate } from "react-router-dom";

const DeviceSettings = () => {
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const deviceData = useSelector(device);
  const dispatch = useDispatch();
  const currentWalletId = useSelector((state) => state.wallets.currentWalletId);
  const currentWallet = useSelector((state) =>
    getWalletAttributes(state, currentWalletId)
  );
  const [transitionType, setTransitionType] = useState(
    deviceData?.attributes?.transition_type || "fade"
  );
  const [timing, setTiming] = useState(
    deviceData?.attributes?.timing ? parseInt(deviceData.attributes.timing) : 60
  );
  const [showInfos, setShowInfos] = useState(
    deviceData?.attributes?.show_infos || false
  );
  const deviceId = currentWallet?.current_device_id;

  useEffect(() => {
    if (!deviceData && deviceId) {
      dispatch(showDevice({ id: deviceId }));
    }
  }, [deviceId, deviceData, dispatch]);

  // console.log("deviceData", deviceData);

  const saveSettings = () => {
    dispatch(
      updateDevice({
        id: deviceId,
        transition_type: transitionType,
        timing,
        show_info: showInfos,
        pairing_status: "ready",
        callbackSuccess: () => {
          navigate("/final");
        },
      })
    );
  };

  return (
    <div className="DeviceSettings">
      <div className="container">
        {account && deviceData && (
          <>
            <div className="">
              <div className="my-5">
                <h2>Transition</h2>
                <Row className="my-3">
                  <Col>
                    <Button
                      color={transitionType === "fade" ? "primary" : "dark"}
                      onClick={() => setTransitionType("fade")}
                      className="w-100 rounded-pill"
                    >
                      Fade
                    </Button>
                  </Col>
                  {/* <Col>
                <Button
                  color={transitionType === "blur" ? "primary" : "dark"}
                  onClick={() => setTransitionType("blur")}
                  className="w-100 rounded-pill"
                >
                  Blur
                </Button>
              </Col> */}
                  <Col>
                    <Button
                      color={transitionType === "swipe" ? "primary" : "dark"}
                      onClick={() => setTransitionType("swipe")}
                      className="w-100 rounded-pill"
                    >
                      Swipe
                    </Button>
                  </Col>
                </Row>

                <h2 className="mt-5">Timing</h2>
                <div className="d-flex align-items-center mb-5">
                  <Button
                    color={"primary"}
                    onClick={() =>
                      setTiming((tim) => {
                        if (parseInt(tim) > 1) {
                          return parseInt(tim) - 1;
                        }
                      })
                    }
                    className="w-100 rounded-pill"
                  >
                    -
                  </Button>
                  <Input
                    type="number"
                    placeholder="Duration"
                    value={timing}
                    onChange={(evt) => {
                      setTiming(parseInt(evt.target.value));
                    }}
                    className="w-100 mx-3 text-center"
                  />
                  <Button
                    color={"primary"}
                    onClick={() => setTiming((tim) => parseInt(tim) + 1)}
                    className="w-100 rounded-pill"
                  >
                    +
                  </Button>
                </div>

                <h2 className="mt-5">Show infos on screen</h2>
                <Row className="my-3">
                  <Col>
                    <Button
                      color={showInfos ? "primary" : "dark"}
                      onClick={() => setShowInfos(true)}
                      className="w-100 rounded-pill"
                    >
                      Yes
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      color={!showInfos ? "primary" : "dark"}
                      onClick={() => setShowInfos(false)}
                      className="w-100 rounded-pill"
                    >
                      No
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="my-3 d-flex justify-content-center">
              <Button
                className="w-100 rounded-pill"
                onClick={() => saveSettings()}
                size="lg"
              >
                Finish
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DeviceSettings;
