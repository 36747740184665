import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

import { MAINNET_CHAINS } from "./chains";

const optionalChains = Object.keys(MAINNET_CHAINS).map(Number);

export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    {
      console.log("process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID", process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID);
      return new WalletConnectV2({
      actions,
      defaultChainId: 1,
      options: {
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
        chains: [1],
        optionalChains,
        showQrModal: true,
      },
    })}
);
