// import { injected } from "./utils/Web3Connectors";
import React, { useEffect } from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Button } from "reactstrap";
import { googleWallet } from "../../connectors/googleWallet";
import { MAINNET_CHAINS } from "../../connectors/chains";
import { FcGoogle } from "react-icons/fc";

const GoogleConnectButton = (props) => {
  //props from web 3 must be instantiated wherever function is used
  const urlParams = new URLSearchParams(window.location.hash);
  const sessionId = urlParams.get("#sessionId");
  // useEffect(() => {
  //   void googleWallet
  //     .connectEagerly()
  //     .then(() => {
  //       console.debug("Connected eagerly to web3-auth");
  //       dispatch(setKeepWalletConnection(true));
  //     })
  //     .catch(() => {
  //       console.debug("Failed to connect eagerly to web3-auth");
  //     });
  // }, []);

  useEffect(() => {
    if (sessionId ) {
      handleConnect();
    }
  }, [sessionId]);

  const handleConnect = () => {
    googleWallet
      .setOptions({
        chainId: 1,
        chainHex: "0x1",
        initOptions: {
          chainId: "0x1",
          rpcTarget: MAINNET_CHAINS[1].urls[0],
          displayName: "Ethereum Mainnet",
          blockExplorer: "https://etherscan.io",
          ticker: "ETH",
          tickerName: "Ethereum",
        },
        loginOptions: {
          google: {
            name: "Custom Auth Login",
            verifier: process.env.REACT_APP_WTA_GOOGLE_VERIFIER,
            typeOfLogin: "google",
            clientId: process.env.REACT_APP_WTA_GOOGLE_ID,
          },
        },

        clientId: process.env.REACT_APP_WTA_ID,
      })
      .then(() => googleWallet.activate())
      .then(() => {
        console.log("connected");
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  return (
    <Button
      onClick={() => handleConnect()}
      className={`text-start py-2 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
        false && "active"
      }`}
      block
      color="transparent"
    >
      <FcGoogle alt={"Google"} size={32} className="me-4" />
      <span className="h5 fw-bold">{"Google"}</span>
    </Button>
  );
};

export default GoogleConnectButton;
