import * as React from "react";

const LedgerIcon = ({ width, className }) => {
  return (
    <svg
      width={width}
      // height="200px"
      className={className}
      viewBox="0 0 200 200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="ledger"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect fill="#000000" x="0" y="0" width="200" height="200"></rect>
        <path
          d="M26,127.6548 L26,164 L81.293,164 L81.293,155.94 L34.05631,155.94 L34.05631,127.6548 L26,127.6548 Z M164.944,127.6548 L164.944,155.94 L117.707,155.94 L117.707,163.998 L173,163.998 L173,127.6548 L164.944,127.6548 Z M81.3733,72.3452 L81.3733,127.6529 L117.707,127.6529 L117.707,120.3842 L89.4296,120.3842 L89.4296,72.3452 L81.3733,72.3452 Z M26,36 L26,72.3452 L34.05631,72.3452 L34.05631,44.05844 L81.293,44.05844 L81.293,36 L26,36 Z M117.707,36 L117.707,44.05844 L164.944,44.05844 L164.944,72.3452 L173,72.3452 L173,36 L117.707,36 Z"
          id="Shape"
          fill="#ffffff"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default LedgerIcon;
