/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";
import Cookies from "universal-cookie";
const cookieHandler = new Cookies(null, { path: "/" });

export const walletsSlice = createSlice({
  name: "wallets",
  initialState: {
    ...defaultInitialState,
    keepWalletConnection: false,
    stored: true,
    confirming: false,
    confirmed: null,
    confirmError: null,
    confirmSuccess: null,
    sentConfirmation: false,
    sendingConfirmation: false,
    sendConfirmationError: null,
  },
  reducers: {
    show: defaultShow,
    showSuccess: (state, action) => {
      defaultShowSuccess(state, action);
      state.confirmed = action.payload?.attributes?.confirmed;
    },
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    siwe: (state) => {
      state.siweRunning = true;
      state.siweSuccess = false;
      state.siweError = null;
      state.currentWalletId = null;
    },
    siweSuccess: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = true;
      state.currentWalletId = action.payload.data.id;
      state.dictionary[action.payload.data.id] = action.payload.data;
      state.siweError = null;
      cookieHandler.set("access-token", action.payload.signature, {
        sameSite: true,
        path: "/",
      });
      cookieHandler.set("uid", action.payload.data.attributes.address, {
        sameSite: true,
        path: "/",
      });
      cookieHandler.set("code", action.payload.code, {
        sameSite: true,
        path: "/",
      });


    },
    siweFail: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = false;
      state.currentWalletId = null;
      state.siweError = action.payload.mess;
      cookieHandler.remove("access-token", { sameSite: true, path: "/" });
      cookieHandler.remove("uid", { sameSite: true, path: "/" });
      cookieHandler.remove("code", { sameSite: true, path: "/" });
    },

    siweLogout: (state, action) => {
      state.siweRunning = false;
      state.siweSuccess = false;
      state.siweError = null;
      state.currentWalletId = null;
      state.keepWalletConnection = false;
      cookieHandler.remove("access-token", { sameSite: true, path: "/" });
      cookieHandler.remove("uid", { sameSite: true, path: "/" });
      cookieHandler.remove("code", { sameSite: true, path: "/" });
    },
    prepareSiwe: (state) => {
      state.prepareSiweRunning = true;
      state.prepareSiweSuccess = false;
      state.prepareSiweError = null;
      state.currentWalletId = null;
      cookieHandler.remove("access-token", { sameSite: true, path: "/" });
      cookieHandler.remove("uid", { sameSite: true, path: "/" });
      cookieHandler.remove("code", { sameSite: true, path: "/" });
    },
    prepareSiweSuccess: (state, action) => {
      state.keepWalletConnection = true;
      state.prepareSiweRunning = false;
      state.prepareSiweSuccess = true;
      state.prepareSiweError = null;
      state.signature = action.payload.signature;
    },
    setKeepWalletConnection: (state, action) => {
      state.keepWalletConnection = action.payload;
    },
    prepareSiweFail: (state, action) => {
      state.prepareSiweRunning = false;
      state.prepareSiweSuccess = false;
      state.currentWalletId = null;
      state.prepareSiweError = action.payload.mess;
      // cookieHandler.remove("access-token", { sameSite: true, path: "/" });
    },
    confirm: (state) => {
      state.confirming = true;
      state.confirmed = false;
      state.confirmErrorMessage = null;
      state.confirmError = false;
    },
    confirmSuccess: (state, action) => {
      state.confirming = false;
      state.confirmed = true;
      state.dictionary[action.payload.id] = action.payload;
      state.confirmError = false;
      state.sendConfirmationError = null;
    },
    confirmFail: (state, action) => {
      state.confirming = false;
      state.confirmed = false;
      state.confirmError = true;
      if (action.payload?.response?.body?.data) {
        state.errorsObject = action.payload.response.body.data;
      } else {
        state.confirmErrorMessage = action.payload.message;
      }
    },
    sendConfirmation: (state) => {
      state.sendingConfirmation = true;
    },
    sendConfirmationSuccess: (state, action) => {
      state.sentConfirmation = true;
      state.sendConfirmationError = null;
      state.dictionary[action.payload.id] = action.payload;
    },
    sendConfirmationFail: (state, action) => {
      state.sentConfirmation = false;
      state.sendConfirmationError = action.payload;
    },

    discardConfirmation: (state) => {
      state.discardConfirmationRunning = true;
      state.discardConfirmationSuccess = false;
      state.discardConfirmationError = null;
    },
    discardConfirmationSuccess: (state, action) => {
      state.discardConfirmationRunning = false;
      state.discardConfirmationSuccess = true;
      state.dictionary[action.payload.id] = action.payload;
      state.confirmed = action.payload?.attributes?.confirmed;
      state.discardConfirmationError = null;
    },
    discardConfirmationFail: (state, action) => {
      state.discardConfirmationRunning = false;
      state.discardConfirmationSuccess = false;
      state.discardConfirmationError = action.payload;
    },
    addToDictionary: defaultAddToDictionary,
  },
});
export const {
  siwe: walletSiwe,
  siweLogout,
  prepareSiwe: prepareWalletSiwe,
  show: showWallet,
  create: createWallet,
  update: updateWallet,
  confirm: confirmWallet,
  sendConfirmation: sendWalletConfirmation,
  setKeepWalletConnection,
  discardConfirmation: discardConfirmationWallet,
} = walletsSlice.actions;

export const actions = walletsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const wallets = (state) =>
  state.wallets.idsList.map((id) => state.wallets.dictionary[id]);
export const wallet = (state) =>
  state.wallets.showId && state.wallets.dictionary[state.wallets.showId];
export const walletAttributes = (state) => wallet(state)?.attributes;
export const walletRelationships = (state) => wallet(state)?.relationships;
export const walletId = (state) => state.wallets.showId;

export const getWallet = createSelector(
  (state) => state.wallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getWalletAttributes = createSelector(
  (state) => state.wallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getWalletRelationships = createSelector(
  (state) => state.wallets.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getWalletByAddress = createSelector(
  (state) => state.wallets.dictionary,
  (_, address) => address,
  (dictionary, address) => {
    let getWalletByAddressIdx =
      dictionary &&
      address &&
      Object.keys(dictionary).find(
        (key) => dictionary[key].attributes.address === address
      );
    if (getWalletByAddressIdx) {
      return dictionary[getWalletByAddressIdx];
    }
    return null;
  }
);

export const getWallets = createSelector(
  (state) => state.wallets.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default walletsSlice.reducer;
