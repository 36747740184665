import React, { useEffect } from "react";
import logo from "../assets/icons/Samsung_wordmark_white.svg";
import background from "../assets/images/background2.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  device,
  disconnectDevice,
  fetchDeviceStatus,
  updateDevice,
} from "../app/devices";
import { Button, Col, Container, Row } from "reactstrap";
import QRCode from "react-qr-code";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";

const TvRoot = () => {
  const dispatch = useDispatch();
  const deviceData = useSelector(device);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const queryDuid = searchParams.get("duid");
  const storageDuid = localStorage.getItem("duid");
  const duid = queryDuid || storageDuid;
  const quickAccessCode = ["ArrowUp", "ArrowUp", "ArrowDown", "ArrowDown"];
  const [accessCode, setAccessCode] = React.useState([]);
  const [isDashboard, setIsDashboard] = React.useState(false);
  useEffect(
    () => {
      if (queryDuid && queryDuid !== storageDuid) {
        localStorage.setItem("duid", queryDuid);
      }
      if (storageDuid && queryDuid !== storageDuid) {
        setSearchParams({ duid: storageDuid });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryDuid, storageDuid]
  );

  useEffect(() => {
    dispatch(fetchDeviceStatus({ uid: duid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notAssociatied = deviceData && !deviceData.relationships?.wallet?.data;
  const pairingStatus = deviceData?.attributes?.pairing_status || "unpaired";
  const unpaired = pairingStatus === "unpaired";
  const paired = pairingStatus === "paired";
  const pairing = pairingStatus === "pairing";
  const ready = pairingStatus === "ready";
  const connected = !!deviceData?.relationships?.wallet?.data?.id;
  const pausedFromDevice = useSelector(
    (state) => state.devices.pausedFromDevice
  );

  useEffect(() => {
    let time = 10000;
    if (connected && (paired || ready)) time = 10000 * 60;
    const intervalId = setInterval(() => {
      console.log("Calling Update");
      // if (connected) {
      //   clearInterval(intervalId);
      // }
      // we call create but this works as a show here
      dispatch(fetchDeviceStatus({ uid: duid }));
    }, time);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, duid, connected, paired]);

  useEffect(() => {
    if (location.pathname?.startsWith("/tv/contracts/")) return;
    if (location.pathname?.startsWith("/tv/slideshow")) return;
    if (location.pathname?.startsWith("/tv/settings")) return;
    if (connected && ready && !pausedFromDevice) {
      navigate("/tv/slideshow");
    }
    if (connected && paired) {
      navigate("/tv/wallet");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData, navigate]);

  useEffect(() => {
    if (!notAssociatied || !pairing) return;
    const handleKeyDown = (e) => {
      if (e.key === "Enter" || e.key === "Backspace" || e.key === "XF86Back") {
        dispatch(disconnectDevice({ uid: duid }));
      }

      console.log(e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notAssociatied, pairing]);

  const handleAccessCode = () => {
    if (notAssociatied)
      dispatch(
        updateDevice({
          id: deviceData.id,
          uid: duid,
          test_access: true,
          callbackSuccess: () => {
            console.log("Access code entered");
          },
        })
      );
  };

  useEffect(() => {
    if (notAssociatied) return;
    if (location.pathname?.startsWith("/tv/contracts")) return;
    if (location.pathname?.startsWith("/tv/slideshow")) return;
    if (location.pathname?.startsWith("/tv/settings")) return;
    const handleKeyDown = (e) => {
      if (e.key === "Backspace" || e.key === "XF86Back") {
        window.close();
      }
      console.log("TVROOT Pressed", e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, deviceData, notAssociatied]);

  useEffect(() => {
    if (!notAssociatied) return;
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        setAccessCode((currentAccessCode) => {
          if (currentAccessCode.join("") === quickAccessCode.join("")) {
            handleAccessCode();
          }
          return [];
        });
      } else {
        setAccessCode((currentAccessCode) => {
          const newAccessCode = [...currentAccessCode, e.key];
          return newAccessCode;
        });
      }
      console.log("TVROOT Pressed", e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, deviceData, notAssociatied]);

  if (notAssociatied) {
    return (
      <div className="tv-main-wrapper vh-100">
        <Container fluid className="h-100">
          <Row className="h-100">
            <Col xs={5} className="h-100">
              <h1 className="mb-0">
                <img
                  width="7052"
                  height="1080"
                  alt="SAMSUNG"
                  src={logo}
                  style={{ height: "1.5625vw", width: "auto" }}
                />
              </h1>
              <span className="h1 mt-0 text-muted fs-32">
                <strong>NFT Gallery</strong>
              </span>
              {notAssociatied && pairing && (
                <div className="d-flex flex-column justify-content-around h-100">
                  <h1 style={{ fontSize: "2.5vw" }}>
                    Please connect your wallet within the app to continue
                  </h1>

                  <Button
                    color={"secondary"}
                    onClick={() => {
                      dispatch(disconnectDevice({ uid: duid }));
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                      if (e.key === "Enter") {
                        dispatch(disconnectDevice({ uid: duid }));
                      }
                    }}
                    size="lg"
                    className="rounded-pill"
                  >
                    Back
                  </Button>
                  <h2
                    style={{ fontSize: "2.5vw", letterSpacing: "1vw" }}
                    className="ms-24"
                  >
                    {deviceData?.attributes?.code}
                  </h2>
                </div>
              )}

              {notAssociatied && unpaired && (
                <div className="d-flex flex-column justify-content-around h-100">
                  <h1 style={{ fontSize: "2.5vw" }}>
                    Easily connect your wallet to display your NFTs on this TV
                  </h1>
                  {deviceData?.attributes?.code && (
                    <>
                      <div
                        className="d-inline-block bg-white p-16"
                        style={{ width: "11.66667vw" }}
                      >
                        <QRCode
                          value={`${window.location.origin}?code=${deviceData?.attributes?.code}`}
                          className="w-100 img-fluid"
                        />
                      </div>

                      <div>
                        <p
                          style={{
                            fontSize: "1.5625vw",
                            marginBottom: "1.5625vw",
                          }}
                        >
                          Enter the following code on your smartphone to connect
                        </p>
                        <h2 style={{ fontSize: "2.5vw", letterSpacing: "1vw" }}>
                          {deviceData?.attributes?.code}
                        </h2>
                      </div>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <div
          style={{ zIndex: "-1" }}
          className="position-fixed top-0 end-0 vw-100 vh-100"
        >
          <div className="vw-100 vh-100 overflow-hidden position-relative">
            <div className="image-overlay"></div>
            <div className="d-flex flex-column justify-content-end align-items-end text-end vh-100">
              <video
                src="https://gen3.samsung.com/640x650_Mint_prev.mp4"
                autoPlay
                loop
                muted
                className="object-fit-cover vw  -100"
                style={{ marginRight: "-50%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <>{!deviceData ? <LoadingScreen /> : <Outlet />}</>;
  }
};

export default TvRoot;
