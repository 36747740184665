/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, delay, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { v4 as uuidv4 } from "uuid";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";
import apiClient, {
  applyHeaders,
  applyDeviceHeaders,
} from "../apiSwaggerRequest";
// import { providers } from "ethers";

export function* show(action) {
  const { id } = action.payload;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Devices.showDevice,
      {
        id,
      },
      { requestInterceptor: applyHeaders() }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* showWithPairingToken(action) {
  const { pairing_token } = action.payload;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Devices.showDeviceWithPairingToken,
      {
        pairing_token,
      },
      { requestInterceptor: applyHeaders() }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const { id, callbackSuccess, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Devices.updateDevice,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(),
        requestBody,
      }
    );

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

export function* pair(action) {
  const { code, callbackSuccess, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Devices.pairDevice,
      {
        code,
      },
      {
        requestInterceptor: applyHeaders(),
        requestBody,
      }
    );

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.pairSuccess(payload.obj.data));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (e) {
    yield put(actions.pairFail({ message: e.message, ...e }));
  }
}

export function* status(action) {
  yield delay(500);
  const { uid, callbackSuccess } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Devices.deviceStatus,
      {
        uid,
      },
      {
        requestInterceptor: applyDeviceHeaders(),
      }
    );

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.statusSuccess(payload.obj.data));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (e) {
    yield put(actions.statusFail({ message: e.message, ...e }));
  }
}

export function* create(action) {
  yield delay(500);
  const { callbackSuccess, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(SwaggerClient.apis.Devices.createDevice, {
      requestInterceptor: applyHeaders(),
      requestBody,
    });

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.createSuccess(payload.obj.data));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (e) {
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}

export function* disconnect(action) {
  yield delay(500);
  const { uid, callbackSuccess } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Devices.deviceDisconnect,
      {
        uid,
      },
      {
        requestInterceptor: applyDeviceHeaders(),
      }
    );

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.disconnectSuccess(payload.obj.data));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (e) {
    yield put(actions.disconnectFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* tokensSaga() {
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.showWithPairingToken, showWithPairingToken);
  yield takeLatest(actions.create, create);
  yield takeLatest(actions.pair, pair);
  yield takeLatest(actions.status, status);
  yield takeLatest(actions.disconnect, disconnect);
}
export default tokensSaga;
