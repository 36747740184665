// import { injected } from "./utils/Web3Connectors";
import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Badge, Button } from "reactstrap";
import { ledgerWallet } from "../../connectors/ledgerWallet";
import { LedgerIcon } from "../../assets/icons";

const LedgerConnectButton = (props) => {
  //props from web 3 must be instantiated wherever function is used

  // useEffect(() => {
  //   ledgerWallet.connectEagerly()
  //   .then(()=> {
  //     console.debug("Connected eagerly to ledger");
  //     dispatch(setKeepWalletConnection(true));
  //   })
  //   .catch(() => {
  //     console.debug("Failed to connect eagerly to ledger");
  //   });
  // }, []);

  const handleConnect = () => {
    ledgerWallet
      .activate(1)
      .then(() => {
        console.log("connected");
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  return (
    <Button
      onClick={() => handleConnect()}
      color="transparent"
      className={`text-start py-2 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
        false && "active"
      }`}
      block
    >
      <LedgerIcon width={32} className="me-4" />
      <span className="h5 fw-bold">{"Ledger"}</span>
      <Badge
        size="sm"
        color="secondary"
        style={{ fontSize: "0.6rem" }}
        className="ms-2"
      >
        BETA
      </Badge>
    </Button>
  );
};

export default LedgerConnectButton;
