import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import createRootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: [
          "payload.callbackSuccess",
          "payload.successCallbackFnc",
          "payload.provider",
          "payload.connector",
        ],
      },
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
