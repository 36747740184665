import { initializeConnector } from "@web3-react/core";
import { Web3AuthWallet } from "./lib/web3AuthWallet";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { MAINNET_CHAINS } from "./chains";

export const [googleWallet, hooks] = initializeConnector(
  (actions) =>
    new Web3AuthWallet({
      actions,
      options: {
        chainId: 1,
        chainHex: "0x1",
        loginProvider: "google",
        initOptions: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: "0x1",
          rpcTarget: MAINNET_CHAINS[1].urls[0],
        },
        loginOptions: {
          google: {
            name: "Custom Auth Login",
            verifier: process.env.REACT_APP_WTA_GOOGLE_VERIFIER, // Please create a verifier on the developer dashboard and pass the name here
            typeOfLogin: "google", // Pass on the login provider of the verifier you've created
            clientId: process.env.REACT_APP_WTA_GOOGLE_ID, // Pass on the clientId of the login provider here - Please note this differs from the Web3Auth ClientID. This is the JWT Client ID
          },
        },
      },
    })
);