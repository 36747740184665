import React from "react";

import MetamaskConnectButton from "./MetamaskConnectButton";
import WalletConnectButton from "./WalletConnectButton";
import CoinbaseConnectButton from "./CoinbaseConnectButton";
import LedgerConnectButton from "./LedgerConnectButton";
import GoogleConnectButton from "./GoogleConnectButton";

const ConnectWalletSection = (props) => {
  const { selectedProvider } = props;
  const showMetaMask = !selectedProvider || selectedProvider === "metamask";
  const showWalletConnect =
    !selectedProvider || selectedProvider === "walletconnect";
  const showCoinbase = !selectedProvider || selectedProvider === "coinbase";
  const showLedger = !selectedProvider || selectedProvider === "ledger";
  const showGoogle = !selectedProvider || selectedProvider === "google";

  return (
    <>
      <div className="flex-sm-column justify-content-center">
        {showMetaMask && (
          <div className="me-2 me-lg-0">
            <MetamaskConnectButton selectedProvider={selectedProvider} />
          </div>
        )}
        {showLedger && (
          <div className="me-2 me-lg-0">
            <LedgerConnectButton />
          </div>
        )}
        {showCoinbase && (
          <div className="me-2 me-lg-0">
            <CoinbaseConnectButton selectedProvider={selectedProvider}/>
          </div>
        )}

        {showWalletConnect && (
          <div className="me-2 me-lg-0">
            <WalletConnectButton />
          </div>
        )}
        <>
          {!selectedProvider && (
            <div className="mt-5 mb-3 d-none d-lg-flex px-lg-5 align-items-center text-center">
              <span
                className="border-bottom border-muted mx-3 mb-2"
                style={{ width: "30px" }}
              ></span>
              <span className="small text-muted">{"Social Connect"}</span>
              <span
                className="border-bottom border-muted mx-3 mb-2"
                style={{ width: "30px" }}
              ></span>
            </div>
          )}
          {showGoogle && (
            <div className="me-2 me-lg-0">
              <GoogleConnectButton />
            </div>
          )}
        </>
      </div>
    </>
  );
};

/**  define proptype for the 'translation' function  */

export default ConnectWalletSection;
