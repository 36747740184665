/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery } from "redux-saga/effects";
// import { v4 as uuidv4 } from "uuid";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";
import apiClient, { applyHeaders } from "../apiSwaggerRequest";
// import { providers } from "ethers";

export function* list(action) {
  const { contract_id } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.FeaturedTokens.listFeaturedTokens,
      {
        contract_id,
      },
      {
        requestInterceptor: applyHeaders(),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.listSuccess({ ...payload }));
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* tokensSaga() {
  yield takeEvery(actions.list, list);
}
export default tokensSaga;
