/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import contracts from "./contracts";
import devices from "./devices";
import tokens from "./tokens";
import wallets from "./wallets";
import featuredTokens from "./featuredTokens";

// import { i18nState } from "redux-i18n";
/** exports all reducers through the combineReducers function */
const rootReducer = () =>
  combineReducers({
    // i18nState,
    contracts,
    devices,
    tokens,
    wallets,
    featuredTokens,
  });
export default rootReducer;
