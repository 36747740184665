/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
// import { v4 as uuidv4 } from "uuid";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";
import apiClient, { applyHeaders } from "../apiSwaggerRequest";
// import { providers } from "ethers";

/** Show Saga
 *  @description: connects to the showResourceName operation
 *  @param {number} action.payload the ResourceName id
 */
export function* show(action) {
  const address = action.payload.walletAddress;
  // let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Wallets.showWallet,
      { address },
      { requestInterceptor: applyHeaders() }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* create(action) {
  let headers = {};
  let { account, callbackSuccess } = action.payload;
  console.log(account);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Wallets.createWallet,
      {},
      {
        requestInterceptor: applyHeaders(headers),
        requestBody: {
          address: account,
        },
      }
    );
    if (payload.obj?.included)
      yield storeIncluded({ payload: payload.obj.included });
    yield put(actions.createSuccess(payload.obj.data));
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (e) {
    yield put(actions.createFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const { projectId, address, callbackFnc, ...requestBody } = action.payload;
  // let headers = yield buildHeaders();
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Wallets.updateWalletForProject,
      { project_id: projectId, address: address },
      {
        requestInterceptor: applyHeaders(),
        requestBody,
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj?.included });

    yield put(actions.updateSuccess(payload.obj.data));
    if (callbackFnc) callbackFnc();
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

export function* siwe(action) {
  yield delay(500);

  // let headers = yield buildHeaders();
  let { id, signature, code, callbackSuccess } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Wallets.walletSiwePairing,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(),
        requestBody: {
          signature,
          code,
        },
      }
    );

    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(
      actions.siweSuccess({
        data: payload.obj.data,
        signature: signature,
        code: code,
      })
    );
    if (callbackSuccess) callbackSuccess();
  } catch (e) {
    yield put(actions.siweFail({ message: e.message, ...e }));
  }
}

export function* prepareSiwe(action) {
  yield delay(500);

  let {
    account,
    callbackSuccess,
    provider,
    // connector,
    // chainId,
    nonce,
    nonceIssuedAt,
    code,
  } = action.payload;
  try {
    const ethersProvider = provider;
    // ||
    // (connector?.provider &&
    //   providers.Web3Provider(connector?.provider, "any")) ||
    // (window.ethereum && providers.Web3Provider(window.ethereum, "any"));
    const signer = ethersProvider.getSigner();

    const message = `Samsung wants you to sign in with your Ethereum account:
${account}

Thanks for using Samsung! Sign this message to login. No password needed!

Code: ${code}
Version: 1
Nonce: ${nonce}
Issued At: ${nonceIssuedAt}
`;
    console.log(message);
    const signature = yield signer.signMessage(message);

    yield put(actions.prepareSiweSuccess({ signature }));
    callbackSuccess({
      signature,
    });
  } catch (e) {
    yield put(actions.prepareSiweFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* walletSaga() {
  yield takeLatest(actions.prepareSiwe, prepareSiwe);
  yield takeLatest(actions.siwe, siwe);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.update, update);
}
export default walletSaga;
