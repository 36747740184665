import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import {
  contract,
  getContractRelationships,
  showContract,
} from "../app/contracts";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import TokenCard from "../components/TokenCard";
import { MdVerified } from "react-icons/md";

const ContractDetail = () => {
  const { account } = useWeb3React();
  const contractData = useSelector(contract);
  const { contractId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!contractData || contractData.id !== contractId) {
      dispatch(showContract({ id: contractId }));
    }
  }, [contractId, contractData, dispatch]);

  // console.log("contractData", contractData);

  const relationships = useSelector((state) =>
    getContractRelationships(state, contractId)
  );
  const tokenIds = relationships?.tokens?.data?.map((token) => token.id);
  // console.log("relationships", relationships);

  return (
    <>
      <div
        style={{ zIndex: "-1" }}
        className="position-absolute top-0 end-0 start-50 bottom-0"
      >
        <div className="h-100 overflow-hidden position-relative">
          <div className="image-overlay"></div>
          {contractData?.attributes?.header?.type === "video" && (
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <video
                src={contractData?.attributes?.header?.url}
                autoPlay
                loop
                webkit-playsinline
                playsinline
                muted
                className="w-100"
              />
            </div>
          )}
          {contractData?.attributes?.header?.type === "image" && (
            <img
              src={contractData?.attributes?.header?.url}
              alt="contract"
              className="object-fit-cover h-100"
            />
          )}
        </div>
      </div>
      <Container className="ContractDetail text-start text-muted">
        {account && contractData && (
          <Row className="my-4">
            <Col className="text-container">
              <div className="my-4">
                <h1>
                  {contractData.attributes?.name}{" "}
                  {contractData.attributes?.verified && (
                    <MdVerified className="mx-1" />
                  )}
                </h1>

                <p className="my-4">{contractData.attributes?.description}</p>
              </div>
              <h3 className="mt-5">Your tokens</h3>
              <Row>
                {tokenIds.map((tokenId) => (
                  <Col xs={4} md={3} key={tokenId}>
                    <TokenCard tokenId={tokenId} identifierAsName />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ContractDetail;
