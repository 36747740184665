/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import { all } from "redux-saga/effects";
import dictionarySaga from "./dictionarySagas";
import contractsSaga from "./contracts/sagas";
import devicesSaga from "./devices/sagas";
import tokensSaga from "./tokens/sagas";
import walletsSaga from "./wallets/sagas";
import featuredTokensSaga from "./featuredTokens/sagas";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    dictionarySaga(),
    contractsSaga(),
    devicesSaga(),
    tokensSaga(),
    walletsSaga(),
    featuredTokensSaga(),
  ]);
}
