/**
 * The Home container
 */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTokenAttributes, updateToken } from "../app/tokens";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import placeholder from "../assets/icons/placeholder.svg";
import { FaPlay, FaRegImages } from "react-icons/fa";
import WebWorkerImage from "./WebWorkerImage";
import WebWorkerVideo from "./WebWorkerVideo";
/**
 * Home container used as app default landing page
 */
const TokenCard = (props) => {
  const videoRef = React.useRef(null);
  const { tokenId, imgOnly, identifierAsName, selected, onTvDevice } = props;
  const [mediaLoaded, setMediaLoaded] = React.useState(false);
  const [canLoadVideo, setCanLoadVideo] = React.useState(false);
  const tokenData = useSelector((state) => getTokenAttributes(state, tokenId));
  const updating = useSelector((state) => state.tokens.updating);
  const hasMimeTypeVideo = tokenData?.mimetype?.startsWith("video");
  // const tokenRelationships = useSelector((state) =>
  //   getTokenRelationships(state, tokenId)
  // );
  const dispatch = useDispatch();

  const toggleVisibility = () => {
    if (updating) return;
    dispatch(updateToken({ id: tokenId, visible: !tokenData?.visible }));
  };

  const playVideo = () => {
    if (!selected) return;
    if (!videoRef?.current?.play) return;
    // if (!videoRef.current.paused) return;
    videoRef.current.play();
  };

  const pauseVideo = () => {
    if (!videoRef?.current?.pause) return;
    // if (videoRef.current.paused) return;
    videoRef.current.pause();
  };

  // useEffect(() => {
  //   // if (!mediaLoaded) return;
  //   if (selected) {
  //     setTimeout(() => {
  //       setCanLoadVideo(true);
  //       playVideo();
  //     }, 500);
  //   } else {
  //     pauseVideo();
  //     setCanLoadVideo(false);
  //   }
  // }, [selected, mediaLoaded]);

  const showSpinner = selected && canLoadVideo && !mediaLoaded;

  return (
    <Card
      className={`border-0 ${
        tokenData?.visible ? "token-visible" : "token-not-visible"
      } ${onTvDevice ? "mb-48" : "mb-3"} ${selected ? "card-selected" : ""}`}
      onClick={() => toggleVisibility()}
      color={selected ? "primary" : "dark"}
    >
      <div
        style={{
          overflow: "hidden",
          position: "relative",
        }}
        className="ratio ratio-1x1"
      >
        {!showSpinner && tokenData?.visible && (
          <div className="positon-relative">
            <div
              style={{
                position: "absolute",
                right: "3%",
                zIndex: "9999",
              }}
              className="h2"
            >
              <FaRegImages />
            </div>
          </div>
        )}
        {showSpinner && (
          <div
            style={{
              position: "absolute",
              top: "0.3rem",
              right: "0.3rem",
              zIndex: "9999",
            }}
          >
            <Spinner color="light" size="sm">
              Loading...
            </Spinner>
          </div>
        )}
        <>
          {tokenData?.animation_url || hasMimeTypeVideo ? (
            <>
              <WebWorkerVideo
                ref={videoRef}
                style={{
                  position: "absolute",
                  margin: "auto auto",
                  backgroundColor: "#000",
                  objectFit: "cover !important",
                }}
                className="object-fit-cover h-100 w-100"
                src={
                  tokenData?.animation_url ||
                  tokenData?.image_medium ||
                  tokenData?.image_image
                }
                loop
                active={selected}
                muted
                // viaWebworker
                poster={tokenData?.image_medium || tokenData?.image}
              />
            </>
          ) : (
            <>
              <WebWorkerImage
                className="card-img-top object-fit-cover h-100 w-100 bg-dark"
                style={{
                  position: onTvDevice ? "absolute" : undefined,
                  margin: "auto auto",
                  backgroundColor: "#000",
                }}
                src={tokenData?.image_medium || tokenData?.image}
                alt={tokenData?.name}
                onError={(event) => {
                  event.target.src = placeholder;
                  event.onerror = null;
                  setMediaLoaded(true);
                }}
                onLoad={(event) => {
                  setMediaLoaded(true);
                }}
              />
            </>
          )}
        </>
      </div>

      {!imgOnly && (
        <CardBody className="tokencard__body overflow-hidden">
          <CardTitle>
            {/* <Link
            to={`/tokens/${tokenData?.contract_address}/${tokenData?.identifier}`}
            
          > */}
            <div style={{ height: onTvDevice ? "1.5em" : "3em" }}>
              <b
                className={`text-truncate w-100 d-inline-block ${
                  !selected ? "text-body" : ""
                }`}
              >
                {identifierAsName
                  ? tokenData?.identifier
                  : tokenData?.name || tokenData?.identifier}
              </b>
            </div>
            {/* </Link> */}
          </CardTitle>
        </CardBody>
      )}
    </Card>
  );
};

TokenCard.propTypes = {
  tokenId: PropTypes.string.isRequired,
  imgOnly: PropTypes.bool,
  identifierAsName: PropTypes.bool,
  selected: PropTypes.bool,
  onTvDevice: PropTypes.bool,
};

/** export the component as redux connected component */
export default TokenCard;
