import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContract,
  getContractRelationships,
  showContract,
} from "../app/contracts";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import TokenCard from "../components/TokenCard";
import { MdVerified } from "react-icons/md";
import { toggleTokenVisibility } from "../app/tokens";
import logo from "../assets/icons/Samsung_wordmark_white.svg";
import WebWorkerVideo from "../components/WebWorkerVideo";
import WebWorkerImage from "../components/WebWorkerImage";
import QRCode from "react-qr-code";
import { FaTimes } from "react-icons/fa";
import { listFeaturedTokens } from "../app/featuredTokens";
import classNames from "classnames";
import FeaturedTokenCard from "../components/FeaturedTokenCard";

const TvContractDetail = () => {
  const { contractId } = useParams();
  const contractData = useSelector((state) => getContract(state, contractId));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);
  const isQrCodeModalValid =
    contractData?.attributes?.featured && contractData?.attributes?.qr_code_url;

  useEffect(() => {
    if (!contractData || contractData.id !== contractId) {
      dispatch(showContract({ id: contractId }));
    }
  }, [contractId, contractData, dispatch]);
  const duid = localStorage.getItem("duid");
  const relationships = useSelector((state) =>
    getContractRelationships(state, contractId)
  );
  const tokenIds = relationships?.tokens?.data?.map((token) => token.id);

  const featuredTokenIds = relationships?.featured_tokens?.data?.map(
    (featuredToken) => featuredToken.id
  );

  const showHighlightsFilter = !!featuredTokenIds?.length;

  const [selectedTab, setSelectedTab] = useState(showHighlightsFilter ? 1 : 2);
  const currentFilterButton =
    selectedTab === 1 ? "button-highlights" : "button-yourtokens";

  useEffect(() => {
    setSelectableElements(getSelectableElements(selectedTab));
    if (selectedTab === 1) {
      dispatch(listFeaturedTokens({ contract_id: contractId }));
    }
  }, [selectedTab]);

  const getSelectableElements = (tab) => {
    let elements = ["button-back"];
    if (showHighlightsFilter) {
      elements.push("button-yourtokens");
      elements.splice(1, 0, "button-highlights");
    }
    if (isQrCodeModalValid) {
      elements.splice(1, 0, "button-qrcode");
    }
    if (tab === 2 && tokenIds?.length) {
      elements.push(...tokenIds.map((token) => `token-${token}`));
      elements = elements.filter(
        (element) => !element.startsWith("featuredtoken")
      );
    }

    if (tab === 1 && featuredTokenIds?.length) {
      elements.push(
        ...featuredTokenIds.map((token) => `featuredtoken-${token}`)
      );
      elements = elements.filter((element) => !element.startsWith("token"));
    }
    return elements;
  };

  const [selectableElements, setSelectableElements] = useState(
    getSelectableElements(selectedTab)
  );

  const [selectedElement, setSelectedElement] = useState(
    selectableElements.length ? selectableElements[0] : null
  );

  const openQrCodeModal = () => {
    if (!selectableElements.includes("button-qrcodemodalclose")) {
      setSelectableElements(["button-qrcodemodalclose"]);
    }
    setIsQrCodeModalOpen(true);
  };

  const closeQrCodeModal = () => {
    setSelectableElements(getSelectableElements(selectedTab));
    setIsQrCodeModalOpen(false);
  };

  useEffect(() => {
    if (!selectedElement && selectableElements.length) {
      setSelectedElement(selectableElements[0]);
    }

    if (isQrCodeModalOpen) {
      setSelectedElement("button-qrcodemodalclose");
    }

    if (!isQrCodeModalOpen && selectedElement === "button-qrcodemodalclose") {
      setSelectedElement("button-qrcode");
    }
  }, [selectableElements, selectedElement]);

  useEffect(() => {
    if (!selectedElement) return;
    // console.log("scrolling to", selectedElement);
    let selectedId = `selector-${selectedElement}`;
    document.getElementById(selectedId)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [selectedElement]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        [
          "Space",
          "ArrowUp",
          "ArrowDown",
          "ArrowLeft",
          "ArrowRight",
          "Backspace",
          "XF86Back",
        ].indexOf(e.key) > -1
      ) {
        e.preventDefault();
      }
      if (e.key === "ArrowUp") {
        setSelectedElement((elem) => {
          const [type, id] = elem.split("-");

          let next;
          if (
            selectableElements.indexOf(elem) - 2 >= 0 &&
            (selectableElements[
              selectableElements.indexOf(elem) - 2
            ].startsWith("token-") ||
              selectableElements[
                selectableElements.indexOf(elem) - 2
              ].startsWith("featuredtoken-"))
          ) {
            next = selectableElements.indexOf(elem) - 2;
          } else if (
            type === "button" &&
            (id === "yourtokens" || id === "highlights")
          ) {
            next = selectableElements.indexOf("button-back");
          } else {
            next = selectableElements.indexOf(currentFilterButton);
          }

          return selectableElements[next];
        });
      }
      if (e.key === "ArrowDown") {
        setSelectedElement((elem) => {
          const [type, id] = elem.split("-");

          let next = selectableElements.indexOf(elem) + 1;
          if (next >= selectableElements.length) {
            next = 0;
          } else if (type === "button" && id === "back" && showHighlightsFilter) {
            next = selectableElements.indexOf(currentFilterButton);
          } else if (
            type === "button" &&
            (id === "yourtokens" || id === "highlights")
          ) {
            next = selectableElements.indexOf(
              selectableElements.find((selectableElement) =>
                selectableElement.includes("token-")
              ) || 0
            );
          } else if (
            selectableElements.length >
            selectableElements.indexOf(elem) + 2
          ) {
            next = selectableElements.indexOf(elem) + 2;
          }
          return selectableElements[next];
        });
      }
      if (e.key === "ArrowLeft") {
        setSelectedElement((elem) => {
          let next = selectableElements.indexOf(elem) - 1;
          if (next < 0) {
            next = selectableElements.length - 1;
          }
          return selectableElements[next];
        });
      }
      if (e.key === "ArrowRight") {
        setSelectedElement((elem) => {
          let next = selectableElements.indexOf(elem) + 1;
          if (next >= selectableElements.length) {
            next = 0;
          }
          return selectableElements[next];
        });
      }

      if (e.key === "Enter") {
        setSelectedElement((selectedEl) => {
          // console.log("Enter", selectedEl);
          const [type, id] = selectedEl.split("-");
          if (type === "button" && id === "back") {
            navigate(`/tv/wallet`);
          }
          if (type === "button" && id === "qrcode") {
            openQrCodeModal();
          }

          if (type === "button" && id === "qrcodemodalclose") {
            closeQrCodeModal();
          }
          if (type === "token") {
            // navigate(`/tv/tokens/${id}`);
            dispatch(toggleTokenVisibility({ id, uid: duid }));
          }
          if (type === "button" && id === "yourtokens") {
            setSelectedTab(2);
          }
          if (type === "button" && id === "highlights") {
            setSelectedTab(1);
          }
          return selectableElements[selectableElements.indexOf(selectedEl)];
        });
      }

      if (e.key === "Backspace" || e.key === "XF86Back") {
        if (isQrCodeModalOpen) closeQrCodeModal();
        else navigate(`/tv/wallet`);
      }

      // console.log(e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableElements]);

  // console.log("selectedElement", selectedElement);

  const headerUrl = (inputUrl) => {
    try {
      // Assuming contractData.attributes.header.url contains your URL
      const url = new URL(inputUrl);

      // Check if the 'w' parameter is present and has the value '500'
      if (url.searchParams.get("w") === "500") {
        // If so, replace 'w=500' with 'w=2000'
        url.searchParams.set("w", "2000");
      }

      // If there's also a need to ensure the format is 'auto', you can enforce that too
      url.searchParams.set("format", "auto");

      // The modified URL as a string
      const modifiedUrl = url.toString();
      return modifiedUrl;
    } catch {
      return inputUrl;
    }
  };

  return (
    <>
      <div className="tv-main-wrapper TvContractDetail">
        <Container>
          <div className="tv-dashboard-header row">
            <div className="col-6">
              <h1 className="mb-0">
                <img
                  width="7052"
                  height="1080"
                  alt="SAMSUNG"
                  src={logo}
                  style={{ height: "1.5625vw", width: "auto" }}
                />
              </h1>
              <span className="h1 mt-0 text-muted fs-32">
                <strong>NFT Gallery</strong>
              </span>
            </div>
            <div className="col-6 text-end">
              <Button
                className="rounded-pill"
                color={
                  selectedElement === "button-back" ? "primary" : "secondary"
                }
                onClick={() => navigate("/tv/wallet")}
                size="lg"
                id="selector-button-back"
              >
                Back
              </Button>
              {isQrCodeModalValid && (
                <Button
                  className="rounded-pill ms-24"
                  color={
                    selectedElement === "button-qrcode"
                      ? "primary"
                      : "secondary"
                  }
                  size="lg"
                  id="selector-button-qrcode"
                >
                  View Collection
                </Button>
              )}
            </div>
          </div>
        </Container>
        <Container className="TvContractDetail text-start text-muted">
          {contractData && (
            <Row className="my-4">
              <Col xs={6} className="text-container">
                <div className="my-4">
                  <h2 className="fs-56">
                    {contractData.attributes?.name}{" "}
                    {contractData.attributes?.verified && (
                      <MdVerified className="mx-1" />
                    )}
                  </h2>

                  <p className="mt-24 mb-5 col-12 fs-48">
                    {contractData.attributes?.description}
                  </p>
                </div>
                <p className="h4 my-48">
                  {showHighlightsFilter && (
                    <>
                      <span
                        className={classNames(
                          "fs-48 rounded-pill px-24 btn",
                          selectedTab === 1
                            ? "border border-primary border-1"
                            : "",
                          selectedElement === "button-highlights" &&
                            "btn-primary selected-text",
                          {
                            "text-muted":
                              selectedElement !== "button-highlights",
                          }
                        )}
                        id={`selector-button-highlights`}
                      >
                        Highlights
                      </span>
                      <span
                        className={classNames(
                          "fs-48 rounded-pill px-24 ms-24 btn",
                          selectedTab === 2
                            ? "border border-primary border-1"
                            : "",
                          selectedElement === "button-yourtokens" &&
                            " btn-primary selected-text",
                          {
                            "text-muted":
                              selectedElement !== "button-yourtokens",
                          }
                        )}
                        id={`selector-button-yourtokens`}
                      >
                        Your Tokens
                      </span>
                    </>
                  )}
                </p>
                {!tokenIds?.length &&
                  contractData?.attributes &&
                  "You do not own any token of this collection."}
                <Row className="mt-24">
                  {selectedTab === 2
                    ? tokenIds.map((tokenId) => (
                        <Col
                          xs={6}
                          md={6}
                          key={tokenId}
                          id={`selector-token-${tokenId}`}
                          className={
                            selectedElement === `token-${tokenId}`
                              ? "selected-card"
                              : ""
                          }
                        >
                          <TokenCard
                            tokenId={tokenId}
                            identifierAsName
                            selected={selectedElement === `token-${tokenId}`}
                            onTvDevice
                          />
                        </Col>
                      ))
                    : featuredTokenIds.map((featuredTokenId) => (
                        <Col
                          xs={6}
                          md={6}
                          key={featuredTokenId}
                          id={`selector-featuredtoken-${featuredTokenId}`}
                          className={
                            selectedElement ===
                            `featuredtoken-${featuredTokenId}`
                              ? "selected-card"
                              : ""
                          }
                        >
                          <FeaturedTokenCard
                            featuredTokenId={featuredTokenId}
                            selected={
                              selectedElement ===
                              `featuredtoken-${featuredTokenId}`
                            }
                            onTvDevice
                          />
                        </Col>
                      ))}
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <div
        style={{ zIndex: "-1" }}
        className="position-fixed top-0 end-0 vw-100 vh-100"
      >
        <div className="w-100 h-100 overflow-hidden position-relative">
          <div className="image-overlay"></div>
          <div className="d-flex flex-column justify-content-end align-items-end text-end h-100">
            <div className="h-100 w-75">
              {contractData?.attributes?.header?.type === "video" && (
                <WebWorkerVideo
                  src={contractData?.attributes?.header?.url}
                  autoPlay
                  loop
                  active
                  muted
                  precacheWebworker
                  className="object-fit-cover w-100 h-100"
                />
              )}
              {contractData?.attributes?.header?.type === "image" && (
                <WebWorkerImage
                  src={headerUrl(contractData?.attributes?.header?.url)}
                  alt="contract"
                  className="object-fit-cover w-100 h-100"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isQrCodeModalOpen}
        toggle={closeQrCodeModal}
        centered
        contentClassName="bg-primary vw-100"
        zIndex={2147483647} // *evil laugh*
      >
        <ModalHeader
          toggle={closeQrCodeModal}
          className="p-48 text-dark"
          close={
            <Button
              className="rounded-pill fs-56"
              color={
                selectedElement === "button-qrcodemodalclose"
                  ? "dark"
                  : "secondary"
              }
              size="lg"
              id="selector-button-qrcodemodalclose"
              onClick={closeQrCodeModal}
            >
              <FaTimes />
            </Button>
          }
        >
          <span className="fs-56">View Collection</span>
        </ModalHeader>
        <ModalBody className="fs-48 p-48">
          <QRCode
            value={contractData?.attributes?.qr_code_url}
            className="w-100 img-fluid"
          />

          <p className="mt-24">
            <a
              className="d-inline-block text-truncate link-dark"
              href={contractData?.attributes?.qr_code_url}
              style={{ maxWidth: "30vw" }}
            >
              {contractData?.attributes?.qr_code_url}
            </a>
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TvContractDetail;
