// import { injected } from "./utils/Web3Connectors";
import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Button } from "reactstrap";
import { metaMask } from "../../connectors/metaMask";
import { MetaMaskIcon } from "../../assets/icons";

const MetamaskConnectButton = (props) => {
  const { selectedProvider } = props;
  const code = localStorage.getItem("code");

  let mobileRedirectUrl = `https://metamask.app.link/dapp/${window.location.host}${window.location.pathname}`;
  if (!mobileRedirectUrl.includes("code")) {
    if (mobileRedirectUrl.includes("?")) {
      mobileRedirectUrl += `&code=${code}`;
    } else {
      mobileRedirectUrl += `?code=${code}`;
    }
  }
  mobileRedirectUrl += `&wallet=metamask`;

  //props from web 3 must be instantiated wherever function is used

  // useEffect(() => {
  //   void metaMask.connectEagerly()
  //   .then(()=> {
  //     console.debug("Connected eagerly to metamask");
  //   })
  //   .catch(() => {
  //     console.debug("Failed to connect eagerly to metamask");
  //   });
  // }, []);

  const handleConnect = () => {
    if (
      window.ethereum &&
      (window.ethereum.isMetaMask ||
        (window.ethereum.providerMap &&
          [...window.ethereum.providerMap].find(
            (entry) => entry[0] === "MetaMask"
          )))
    ) {
      metaMask
        .activate(1)
        .then(() => {
          console.log("connected");
        })
        .catch((err) => console.log(err));
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      !(window.ethereum && window.ethereum.isMetaMask)
    ) {
      window.location = mobileRedirectUrl;
      // window.location +=
      //   devicePairingToken && `?pairing_token=${devicePairingToken}`;
      // window.location += deviceCode && `?code=${deviceCode}`;
      console.log("Opening Mobile Wallet");
      return;
    }
  };

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  return (
    <Button
      onClick={() => handleConnect()}
      color="transparent"
      className={`text-start py-2 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
        false && "active"
      }`}
      block
    >
      <div className={selectedProvider ? "d-flex justify-content-between" : ""}>
        <MetaMaskIcon width={32} className="me-4" />
        <span className="h5 fw-bold">
          {!selectedProvider
            ? "Metamask"
            : "Click here to verify Wallet Ownership"}
        </span>
      </div>
    </Button>
  );
};

export default MetamaskConnectButton;
