import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { device, setPausedFromDevice } from "../app/devices";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import logo from "../assets/icons/logo.png";
import { useNavigate } from "react-router-dom";
import WebWorkerVideo from "../components/WebWorkerVideo";
import WebWorkerImage from "../components/WebWorkerImage";

const TvSlideshow = () => {
  const deviceData = useSelector(device);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showControls, setShowControls] = useState(false);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Backspace" || e.key === "XF86Back") {
        navigate(`/tv/wallet`);
        dispatch(setPausedFromDevice(true));
      }
      setShowControls(true);
      // console.log(e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Remove Controls after 5 second
  useEffect(() => {
    if (!showControls) return;
    setTimeout(() => {
      setShowControls(false);
    }, 5000);
  }, [showControls]);

  const notAssociatied = deviceData && !deviceData.relationships?.wallet?.data;

  const tokensDictionary = useSelector((state) => state.tokens.dictionary);
  const allItems = (deviceData?.relationships?.tokens?.data || []).map(
    (token, idx) => {
      let tokAtt = tokensDictionary[token.id]?.attributes;
      return {
        src: tokAtt?.image,
        src_medium: tokAtt?.image_medium,
        altText: tokAtt?.name,
        title: tokAtt?.name,
        address: tokAtt?.contract_address,
        caption: tokAtt?.description,
        key: idx + 1,
        visible: tokAtt?.visible,
        animation_url: tokAtt?.animation_url,
      };
    }
  );

  const items = allItems.filter((item) => item.visible);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    setTimeout(() => {
      next();
    }, deviceData?.attributes?.timing * 1000 || 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slides = items.map((item, idx) => {
    return (
      <CarouselItem
        className="vh-100"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        {idx === activeIndex && (
          <>
            {item?.animation_url ? (
              <>
                <WebWorkerVideo
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    objectFit: "cover !important",
                  }}
                  className="object-fit-cover h-100 w-100"
                  src={item?.animation_url || item?.src}
                  loop
                  active
                  muted
                  slideShowMode
                  // precacheWebworker
                  poster={item?.src_medium || item?.src}
                />
                <WebWorkerImage
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    filter: "blur(1rem)",
                    zIndex: 0,
                  }}
                  src={item?.src_medium || item?.src}
                  className="object-fit-cover h-100 w-100"
                  alt={item.altText}
                  onError={(event) => {
                    event.target.src = logo;
                    event.onerror = null;
                  }}
                />
              </>
            ) : item?.src ? (
              <>
                <WebWorkerImage
                  src={item.src}
                  style={{ position: "fixed", top: 0, left: 0, zIndex: 100 }}
                  className="object-fit-contain h-100 w-100"
                  alt={item.altText}
                  onError={(event) => {
                    event.target.src = logo;
                    event.onerror = null;
                  }}
                />
                <WebWorkerImage
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    filter: "blur(1rem)",
                    zIndex: 0,
                  }}
                  src={item?.src_medium || item?.src}
                  className="object-fit-cover h-100 w-100"
                  alt={item.altText}
                  onError={(event) => {
                    event.target.src = logo;
                    event.onerror = null;
                  }}
                />
              </>
            ) : (
              ""
            )}
            {deviceData?.attributes.show_info && (
              <CarouselCaption
                captionText={
                  <>
                    <div>{item.address}</div>
                    <div>{item.caption}</div>
                  </>
                }
                captionHeader={item.title}
                className="tv-slideshow__caption"
              />
            )}
          </>
        )}
      </CarouselItem>
    );
  });
  if (notAssociatied) {
    return <h1>Not associated</h1>;
  }
  // console.log(deviceData?.attributes?.timing * 1000);

  const slide = deviceData?.attributes?.transition_type === "swipe";
  const fade = deviceData?.attributes?.transition_type === "fade";

  return (
    <div className="vh-100 tv-slideshow">
      {slides?.length ? (
        <Carousel
          className="vh-100"
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={deviceData?.attributes?.timing * 1000 || 5000}
          pause={false}
          slide={slide}
          fade={fade}
        >
          {slides}

          {showControls && (
            <>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </>
          )}
        </Carousel>
      ) : (
        <div className="vh-100 d-flex flex-column align-items-center justify-content-center">
          <img
            width="7052"
            height="1080"
            alt="SAMSUNG"
            src={logo}
            style={{ height: "4vw", width: "auto" }}
            className="mb-24"
          />
          <h3>No tokens selected, the slideshow is currently empty.</h3>
        </div>
      )}
    </div>
  );
};

export default TvSlideshow;
