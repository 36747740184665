/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const contractsSlice = createSlice({
  name: "contracts",
  initialState: {
    ...defaultInitialState,
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    addToDictionary: defaultAddToDictionary,
  },
});
export const { list: listContracts, show: showContract } =
  contractsSlice.actions;

export const actions = contractsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const contracts = (state) =>
  state.contracts.idsList.map((id) => state.contracts.dictionary[id]);
export const contract = (state) =>
  state.contracts.showId && state.contracts.dictionary[state.contracts.showId];
export const contractAttributes = (state) => contract(state)?.attributes;
export const contractRelationships = (state) => contract(state)?.relationships;
export const contractId = (state) => state.contracts.showId;

export const featuredContractIds = createSelector(
  (state) => state.contracts.dictionary,
  (dictionary) => {
    if (!dictionary) {
      return [];
    }

    // Filter and map in a single step
    const featuredItems = Object.keys(dictionary)
      .filter((id) => dictionary[id]?.attributes?.featured)
      .map((id) => ({
        id,
        // Directly handling null, undefined, or empty string; adjust as needed
        order:
          dictionary[id].attributes.order !== null &&
          dictionary[id].attributes.order !== undefined
            ? parseInt(dictionary[id].attributes.order, 10)
            : Infinity,
      }));

    // Sorting, explicitly checking for Infinity to handle null or undefined 'order'
    featuredItems.sort((a, b) => {
      // If either order is null, undefined, or an empty string, it has been converted to Infinity
      return a.order - b.order;
    });

    // Return the sorted ids
    return featuredItems.map((item) => item.id);
  }
);

export const unfeaturedContractIds = createSelector(
  (state) => state.contracts.dictionary,
  (dictionary) => {
    if (!dictionary) {
      return [];
    }

    // Filter and map in a single step
    const featuredItems = Object.keys(dictionary)
      .filter((id) => !dictionary[id]?.attributes?.featured)
      .map((id) => ({
        id,
        // Directly handling null, undefined, or empty string; adjust as needed
        order:
          dictionary[id].attributes.order !== null &&
          dictionary[id].attributes.order !== undefined
            ? parseInt(dictionary[id].attributes.order, 10)
            : Infinity,
      }));

    // Sorting, explicitly checking for Infinity to handle null or undefined 'order'
    featuredItems.sort((a, b) => {
      // If either order is null, undefined, or an empty string, it has been converted to Infinity
      return a.order - b.order;
    });

    // Return the sorted ids
    return featuredItems.map((item) => item.id);
  }
);

export const walletContractIds = createSelector(
  (state) => state.contracts.dictionary,
  (state) => state.wallets.dictionary,
  (state) => state.wallets.currentWalletId,
  (dictionary, walletsDictionary, walletId) => {
    if (!dictionary || !walletId) {
      return [];
    }
    const currentWallet = walletsDictionary[walletId];
    if (!currentWallet) {
      return [];
    }
    const contractIds = currentWallet.relationships?.contracts?.data?.map(
      (contract) => contract.id
    );
    // Filter and map in a single step
    const walletItems = Object.keys(dictionary)
      .filter((id) => contractIds.includes(id))
      .map((id) => ({
        id,
        // Directly handling null, undefined, or empty string; adjust as needed
        order:
          dictionary[id].attributes.order !== null &&
          dictionary[id].attributes.order !== undefined
            ? parseInt(dictionary[id].attributes.order, 10)
            : Infinity,
      }));

    // Sorting, explicitly checking for Infinity to handle null or undefined 'order'
    walletItems.sort((a, b) => {
      // If either order is null, undefined, or an empty string, it has been converted to Infinity
      return a.order - b.order;
    });

    // Return the sorted ids
    return walletItems.map((item) => item.id);
  }
);



export const getContract = createSelector(
  (state) => state.contracts.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getContractAttributes = createSelector(
  (state) => state.contracts.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getContractRelationships = createSelector(
  (state) => state.contracts.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export default contractsSlice.reducer;
