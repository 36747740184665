import PropTypes from "prop-types";
import * as React from "react";

const LuksoIcon = ({ size }) => {
  return (
    <svg
      xmlnsXodm="http://www.corel.com/coreldraw/odm/2003"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      x={0}
      y={0}
      viewBox="0 0 2500 2500"
      style={{ enableBackground: "new 0 0 2500 2500" }}
      xmlSpace="preserve"
      width={size}
      height={size}
    >
      <style type="text/css">
        {".st0{fill:none;}"}
        {".st1{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}"}
        {".st2{fill-rule:evenodd;clip-rule:evenodd;fill:#DBDBDB;}"}
        {".st3{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}"}
        {".st4{fill-rule:evenodd;clip-rule:evenodd;fill:#EFEDEE;}"}
      </style>
      <g id="Layer_x0020_1">
        <g id="_2287294377904">
          <polygon
            class="st0"
            points="0,0 2500,0 2500,2500 0,2500   "
          ></polygon>
          <g>
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="2151"
              y1="395.2757"
              x2="348.9998"
              y2="2103.2754"
              gradientTransform="matrix(1 0 0 -1 0 2499.2756)"
            >
              <stop offset="0" style={{ stopColor: "#693CB9" }}></stop>
              <stop offset="1" style={{ stopColor: "#ECA19B" }}></stop>
            </linearGradient>
            <polygon
              class="st1"
              points="167,625 709,312 1250,0 1791,313 2333,625 2333,1250 2333,1875 1791,2188 1250,2500 709,2188      167,1875 167,1250    "
            ></polygon>
            <g>
              <polygon
                class="st2"
                points="590,1250 1240,1250 1568,1834 919,1834     "
              ></polygon>
              <polygon
                class="st3"
                points="590,1250 1240,1250 1568,666 919,666     "
              ></polygon>
              <polygon
                class="st4"
                points="1240,1250 1568,666 1910,1258 1568,1834     "
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LuksoIcon.propTypes = {
  size: PropTypes.number,
};

LuksoIcon.defaultProps = {
  size: 16,
};

export default LuksoIcon;
