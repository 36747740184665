/* eslint-disable import/first */
const dotenv = require("dotenv");
dotenv.config();
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import Root from "./routes/Root";
import WalletDashboard from "./routes/WalletDashboard";
import reportWebVitals from "./reportWebVitals";
import "./index.sass";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Web3ReactProvider } from "@web3-react/core";

import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "./connectors/coinbaseWallet";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import {
  hooks as walletConnectHooks,
  walletConnectV2,
} from "./connectors/walletConnect";
import { hooks as ledgerHooks, ledgerWallet } from "./connectors/ledgerWallet";
import {
  googleWallet,
  hooks as googleWalletHooks,
} from "./connectors/googleWallet";
import FourOFour from "./routes/FourOFour";
import ContractDetail from "./routes/ContractDetail";
import DeviceSettings from "./routes/DeviceSettings";
import FinalScreen from "./routes/FinalScreen";
import TvRoot from "./tvRoutes/TvRoot";
import TvSlideshow from "./tvRoutes/TvSlideshow";
import TvWalletDashboard from "./tvRoutes/TvWalletDashboard";
import TvContractDetail from "./tvRoutes/TvContractDetail";
import TvDeviceSettings from "./tvRoutes/TvDeviceSettings";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectHooks],
  [coinbaseWallet, coinbaseWalletHooks],
  [ledgerWallet, ledgerHooks],
  [googleWallet, googleWalletHooks],
];

const container = document.getElementById("root");
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <FourOFour />,
    children: [
      {
        path: "/wallet",
        element: <WalletDashboard />,
      },
      {
        path: "/contracts/:contractId",
        element: <ContractDetail />,
      },
      {
        path: "/settings",
        element: <DeviceSettings />,
      },
      { path: "/final", element: <FinalScreen /> },
    ],
  },
  {
    path: "/tv",
    element: <TvRoot />,
    children: [
      {
        path: "/tv/slideshow",
        element: <TvSlideshow />,
      },
      {
        path: "/tv/wallet",
        element: <TvWalletDashboard />,
      },
      {
        path: "/tv/contracts/:contractId",
        element: <TvContractDetail />,
      },
      {
        path: "/tv/settings",
        element: <TvDeviceSettings />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </Web3ReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// serviceWorkerRegistration.register();
