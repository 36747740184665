/**
 *  Sagas for the ResourceNames resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, select, takeEvery } from "redux-saga/effects";
// import { v4 as uuidv4 } from "uuid";
import { storeIncluded } from "../dictionarySagas";
import { actions } from "./index";
import apiClient, { applyHeaders } from "../apiSwaggerRequest";
// import { providers } from "ethers";

export function* list(action) {
  const { pageNum, itemNum, endlessScroll, search, uid, filter } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Tokens.listTokens,
      {
        search,
        filter,
        page: pageNum,
        items: itemNum,
        uid: uid,
      },
      {
        requestInterceptor: applyHeaders(),
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(
      actions.listSuccess({ ...payload, endlessScroll: !!endlessScroll })
    );
  } catch (e) {
    yield put(actions.listFail({ message: e.message, ...e }));
  }
}

export function* show(action) {
  const { id } = action.payload;

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Tokens.showToken,
      {
        id,
      },
      { requestInterceptor: applyHeaders() }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(actions.showSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.showFail({ message: e.message, ...e }));
  }
}

export function* update(action) {
  const { id, ...requestBody } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Tokens.updateToken,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(),
        requestBody,
      }
    );

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

export function* toggleTokenVisibility(action) {
  const { id, uid } = action.payload;
  try {
    const tokensDictionary = yield select((state) => state.tokens.dictionary);
    const token = tokensDictionary[id];
    const requestBody = {
      visible: !token.attributes?.visible,
      uid: uid,
    };
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.Tokens.updateToken,
      {
        id,
      },
      {
        requestInterceptor: applyHeaders(),
        requestBody,
      }
    );

    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });

    yield put(actions.updateSuccess(payload.obj.data));
  } catch (e) {
    yield put(actions.updateFail({ message: e.message, ...e }));
  }
}

export function* setVisibility(action) {
  const { pageNum, itemNum, endlessScroll, uid } = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.Tokens.setTokensVisibility,
      {
        page: pageNum,
        items: itemNum,
      },
      {
        requestInterceptor: applyHeaders(),
        requestBody: {
          uid: uid,
        },
      }
    );
    if (payload.obj?.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put(
      actions.setVisibilitySuccess({
        ...payload,
        endlessScroll: !!endlessScroll,
      })
    );
  } catch (e) {
    yield put(actions.setVisibilityFail({ message: e.message, ...e }));
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* tokensSaga() {
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.setVisibility, setVisibility);
  yield takeEvery(actions.toggleTokenVisibility, toggleTokenVisibility);
}
export default tokensSaga;
