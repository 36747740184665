// import { injected } from "./utils/Web3Connectors";
import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Badge, Button } from "reactstrap";
import { coinbaseWallet } from "../../connectors/coinbaseWallet";
import { CoinbaseIcon } from "../../assets/icons";

const CoinbaseConnectButton = (props) => {
  const {selectedProvider} = props;
  const code = localStorage.getItem("code");

  let mobileRedirectUrl = `https://go.cb-w.com/dapp?cb_url=${window.location.host}${window.location.pathname}`;
  if (!mobileRedirectUrl.includes("code")) {
    if (mobileRedirectUrl.includes("?")) {
      mobileRedirectUrl += `&code=${code}`;
    } else {
      mobileRedirectUrl += `?code=${code}`;
    }
  }
  mobileRedirectUrl += `&wallet=coinbase`;
  //props from web 3 must be instantiated wherever function is used

  // useEffect(() => {
  //   void metaMask.connectEagerly()
  //   .then(()=> {
  //     console.debug("Connected eagerly to metamask");
  //   })
  //   .catch(() => {
  //     console.debug("Failed to connect eagerly to metamask");
  //   });
  // }, []);

  const handleConnect = () => {
    if (
      window.ethereum &&
      (window.ethereum.isCoinbaseWallet ||
        (window.ethereum.providerMap &&
          [...window.ethereum.providerMap].find(
            (entry) => entry[0] === "CoinbaseWallet"
          )))
    ) {
      coinbaseWallet
        .activate(1)
        .then(() => {
          console.log("connected");
        })
        .catch((err) => {
          console.log("error connecting to coinbase wallet");
          console.log(err);
        });
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ) &&
      !(window.ethereum && window.ethereum.isCoinbaseWallet)
    ) {
      window.location = mobileRedirectUrl;

      console.log("Opening Mobile Wallet");
      return;
    }
  };

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  return (
    <Button
      onClick={() => handleConnect()}
      color="transparent"
      className={`text-start py-2 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
        false && "active"
      }`}
      block
    >
      <CoinbaseIcon width={32} className="me-4" />
      <span className="h5 fw-bold">{!selectedProvider ? "Coinbase Wallet" : "Click here to verify Wallet Ownership"}</span>
      <Badge
        size="sm"
        color="secondary"
        style={{ fontSize: "0.6rem" }}
        className="ms-2"
      >
        BETA
      </Badge>
    </Button>
  );
};

export default CoinbaseConnectButton;
