/**
 * The Home container
 */
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import placeholder from "../assets/icons/placeholder.svg";
import WebWorkerImage from "./WebWorkerImage";
import WebWorkerVideo from "./WebWorkerVideo";
import { getFeaturedTokenAttributes } from "../app/featuredTokens";
/**
 * Home container used as app default landing page
 */
const FeaturedTokenCard = (props) => {
  const videoRef = React.useRef(null);
  const { featuredTokenId, selected, onTvDevice } = props;
  const [mediaLoaded, setMediaLoaded] = React.useState(false);
  const [canLoadVideo, _setCanLoadVideo] = React.useState(false);
  const featuredTokenData = useSelector((state) =>
    getFeaturedTokenAttributes(state, featuredTokenId)
  );

  const showSpinner = selected && canLoadVideo && !mediaLoaded;

  return (
    <Card
      className={`border-0 token-visible ${onTvDevice ? "mb-48" : "mb-3"} ${
        selected ? "card-selected" : ""
      }`}
      color={selected ? "primary" : "dark"}
    >
      <div
        style={{
          overflow: "hidden",
          position: "relative",
        }}
        className="ratio ratio-1x1"
      >
        {showSpinner && (
          <div
            style={{
              position: "absolute",
              top: "0.3rem",
              right: "0.3rem",
              zIndex: "9999",
            }}
          >
            <Spinner color="light" size="sm">
              Loading...
            </Spinner>
          </div>
        )}
        <>
          {featuredTokenData?.video_url ? (
            <>
              <WebWorkerVideo
                ref={videoRef}
                style={{
                  position: "absolute",
                  margin: "auto auto",
                  backgroundColor: "#000",
                  objectFit: "cover !important",
                }}
                className="object-fit-cover h-100 w-100"
                src={
                  featuredTokenData?.video_url || featuredTokenData?.image_url
                }
                loop
                active={selected}
                muted
                poster={featuredTokenData?.image_url}
              />
            </>
          ) : (
            <>
              <WebWorkerImage
                className="card-img-top object-fit-cover h-100 w-100 bg-dark"
                style={{
                  position: onTvDevice ? "absolute" : undefined,
                  margin: "auto auto",
                  backgroundColor: "#000",
                }}
                src={featuredTokenData?.image_url}
                alt="TODO"
                onError={(event) => {
                  event.target.src = placeholder;
                  event.onerror = null;
                  setMediaLoaded(true);
                }}
                onLoad={(event) => {
                  setMediaLoaded(true);
                }}
              />
            </>
          )}
        </>
      </div>

      <CardBody className="tokencard__body overflow-hidden">
        <CardTitle>
          <div style={{ height: onTvDevice ? "1.5em" : "3em" }}>
            <b
              className={`text-truncate w-100 d-inline-block ${
                !selected ? "text-body" : ""
              }`}
            >
              {featuredTokenData?.name}
            </b>
          </div>
        </CardTitle>
      </CardBody>
    </Card>
  );
};

FeaturedTokenCard.propTypes = {
  featuredTokenId: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onTvDevice: PropTypes.bool,
};

/** export the component as redux connected component */
export default FeaturedTokenCard;
