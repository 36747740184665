import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Container } from "reactstrap";
import TokenCard from "../components/TokenCard";
import ContractCard from "../components/ContractCard";
import { useNavigate } from "react-router-dom";
import { listTokens, toggleTokenVisibility } from "../app/tokens";
import { MdOutlineSearch, MdOutlineSearchOff } from "react-icons/md";
import { useState } from "react";
import { featuredContractIds, listContracts } from "../app/contracts";
import logo from "../assets/icons/Samsung_wordmark_white.svg";
import {
  getDashboardElements,
  setPausedFromDevice,
  setSelectedToken,
  setSelectedContract,
  paginatedContractIds,
  paginatedTokenIds,
} from "../app/devices";
import classNames from "classnames";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaCog } from "react-icons/fa";

const TvWalletDashboard = () => {
  const featuredContractsCarouselRef = React.useRef(null);
  const contractsCarouselRef = React.useRef(null);
  const searchInputRef = React.useRef(null);

  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const searching = isSearchVisible && searchQuery.length > 0;
  const tokenIds = useSelector((state) => state.tokens.idsList);
  const [tokenFilter, setTokenFilter] = useState("selected");
  const [isProcessingKey, setIsProcessingKey] = useState(false);
  const paginatedTokens = useSelector((state) =>
    paginatedTokenIds(state, {
      filter: tokenFilter,
    })
  );
  const tokens = searching ? tokenIds : paginatedTokens;
  const contracts = useSelector(paginatedContractIds);
  const featuredContracts = useSelector(featuredContractIds);
  const navigate = useNavigate();
  const duid = localStorage.getItem("duid");
  const selectableElements = useSelector((state) =>
    getDashboardElements(state, {
      searching,
      isSearchVisible,
      tokenFilter,
    })
  );

  const [selectedElement, setSelectedElement] = useState("button-start");
  useEffect(() => {
    if (!selectedElement && selectableElements.length) {
      setSelectedElement(selectableElements[0]);
    }

    if (selectedElement && selectedElement.startsWith("token")) {
      const [_, id] = selectedElement.split("-");
      dispatch(setSelectedToken(id));
    }

    if (selectedElement && selectedElement.startsWith("contract")) {
      const [_, id] = selectedElement.split("-");
      dispatch(setSelectedContract(id));
    }
  }, [selectableElements, selectedElement]);

  useEffect(() => {
    if (!selectedElement) return;
    // console.log("scrolling to", selectedElement);
    const [type, id] = selectedElement.split("-");
    let selectedId = `selector-${selectedElement}`;
    if (type === "featuredContract") {
      selectedId = `selector-featuredContract-row`;
    }
    if (type === "contract") {
      selectedId = `selector-contract-row`;
    }
    document.getElementById(selectedId)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [selectedElement]);

  useEffect(() => {
    dispatch(listContracts({ featured: true, uid: duid }));
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        ["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(
          e.key
        ) > -1
      ) {
        e.preventDefault();
        if (isProcessingKey) return;

        setIsProcessingKey(true);
      }
      if (e.key === "ArrowUp") {
        setSelectedElement((elem) => {
          if (!elem) {
            // console.log("no elem");
            return elem;
          }
          const [type, id] = elem.split("-");
          let next = selectableElements.indexOf(elem) - 1;
          if (type === "featuredContract") {
            next = 0;
          }
          if (type === "contract") {
            if (featuredContracts?.length) {
              next = selectableElements.indexOf(
                "featuredContract-" + featuredContracts[0]
              );
              featuredContractsCarouselRef?.current?.goToSlide(0);
            } else {
              next = selectableElements.indexOf("button-start");
            }
          }
          if (type === "token") {
            if (
              selectableElements.indexOf(elem) - 6 >= 0 &&
              selectableElements[
                selectableElements.indexOf(elem) - 6
              ].startsWith("token-")
            ) {
              next = selectableElements.indexOf(elem) - 6;
            } else {
              // if (featuredContracts?.length) {
              //   next = selectableElements.indexOf("featuredContract-" + contracts[0]);
              // }
              // if (contracts?.length) {
              //   next = selectableElements.indexOf("contract-" + contracts[0]);
              // }
              next = selectableElements.indexOf("button-selected");
            }
          }
          if (
            type === "button" &&
            (id === "all" ||
              id === "selected" ||
              (id === "featured") | (id === "search"))
          ) {
            if (contracts?.length) {
              next = selectableElements.indexOf("contract-" + contracts[0]);
              contractsCarouselRef?.current?.goToSlide(0);
            } else if (featuredContracts?.length) {
              next = selectableElements.indexOf(
                "featuredContract-" + featuredContracts[0]
              );
              featuredContractsCarouselRef?.current?.goToSlide(0);
            }
          }
          if (
            next === 1 &&
            featuredContracts?.length &&
            featuredContractsCarouselRef?.current
          ) {
            featuredContractsCarouselRef.current.goToSlide(0);
          }

          return selectableElements[next];
        });

        setTimeout(() => {
          setIsProcessingKey(false);
        }, 300);
      }
      if (e.key === "ArrowDown") {
        setSelectedElement((elem) => {
          if (!elem) {
            // console.log("no elem");
            return elem;
          }
          const [type, id] = elem.split("-");
          let next = selectableElements.indexOf(elem) + 1;
          if (type === "button" && (id === "start" || id === "settings")) {
            if (featuredContracts?.length) {
              next = selectableElements.indexOf(
                "featuredContract-" + featuredContracts[0]
              );
              featuredContractsCarouselRef?.current?.goToSlide(0);
            } else if (contracts?.length) {
              next = selectableElements.indexOf("contract-" + contracts[0]);
              contractsCarouselRef?.current?.goToSlide(0);
            } else next = selectableElements.indexOf("button-selected");
          }
          if (type === "featuredContract") {
            if (contracts?.length) {
              next = selectableElements.indexOf("contract-" + contracts[0]);
              contractsCarouselRef?.current?.goToSlide(0);
            } else {
              next = selectableElements.indexOf("button-selected");
            }
          }
          if (type === "contract") {
            next = selectableElements.indexOf("button-selected");
          }

          if (
            type === "button" &&
            (id === "all" ||
              id === "selected" ||
              id === "featured" ||
              id === "search")
          ) {
            if (tokens?.length) {
              next = selectableElements.indexOf("token-" + tokens[0]);
            }
          }

          if (
            type === "token" &&
            selectableElements.length >= selectableElements.indexOf(elem) + 6
          ) {
            next = selectableElements.indexOf(elem) + 6;
          }

          return selectableElements[next];
        });

        setTimeout(() => {
          setIsProcessingKey(false);
        }, 300);
      }
      if (e.key === "ArrowLeft") {
        setSelectedElement((elem) => {
          if (!elem) {
            // console.log("no elem");
            return elem;
          }
          const [type, id] = elem.split("-");
          let selectedRef = null;
          let currentIndex = 1;
          let previousElement =
            selectableElements[selectableElements.indexOf(elem) - 1];
          let newIndex = null;
          if (!previousElement) {
            // console.log("no previous element");
            return elem;
          }
          const [previousType, previousId] = previousElement.split("-");
          // console.log("previousType", previousType);
          // console.log("elem", elem);

          if (
            type === "featuredContract" &&
            previousType === "featuredContract"
          ) {
            selectedRef = featuredContractsCarouselRef;
            currentIndex = featuredContracts.indexOf(id);
            newIndex = currentIndex - 1;
          }
          if (type === "contract" && previousType === "contract") {
            selectedRef = contractsCarouselRef;
            currentIndex = contracts.indexOf(id);
            newIndex = currentIndex - 1;
          }
          if (type === "contract" && previousType === "featuredContract") {
            selectedRef = featuredContractsCarouselRef;
            currentIndex = featuredContracts.indexOf(previousId);
            newIndex = currentIndex;
          }

          if (type === "button" && id === "selected") {
            if (previousType === "contract") {
              selectedRef = contractsCarouselRef;
              currentIndex = 0;
              newIndex = currentIndex;
              previousElement = `contract-${contracts[0]}`;
            }
            if (previousType === "featuredContract") {
              selectedRef = featuredContractsCarouselRef;
              currentIndex = 0;
              newIndex = currentIndex;
              previousElement = `featuredContract-${featuredContracts[0]}`;
            }
          }
          if (typeof newIndex === "number" && selectedRef?.current?.state) {
            selectedRef.current.goToSlide(newIndex);
          }
          return previousElement;
        });

        setTimeout(() => {
          setIsProcessingKey(false);
        }, 300);
      }
      if (e.key === "ArrowRight") {
        setSelectedElement((elem) => {
          if (!elem) {
            // console.log("no elem");
            return elem;
          }
          const [type, id] = elem.split("-");
          let selectedRef = null;
          let currentIndex = 0;
          let nextElement =
            selectableElements[selectableElements.indexOf(elem) + 1];
          let newIndex = null;
          if (!nextElement) {
            // console.log("no elem");
            return elem;
          }
          const [nextType, nextId] = nextElement?.split("-");
          if (type === "featuredContract" && nextType === "featuredContract") {
            selectedRef = featuredContractsCarouselRef;
            currentIndex = featuredContracts.indexOf(id);
            newIndex = currentIndex + 1;
          }
          if (type === "contract" && nextType === "contract") {
            selectedRef = contractsCarouselRef;
            currentIndex = contracts.indexOf(id);
            newIndex = currentIndex + 1;
          }
          if (type === "featuredContract" && nextType === "contract") {
            selectedRef = contractsCarouselRef;
            currentIndex = contracts.indexOf(nextId);
            newIndex = currentIndex;
          }
          if (typeof newIndex === "number" && selectedRef?.current?.state) {
            selectedRef.current.goToSlide(newIndex);
          }

          return selectableElements[selectableElements.indexOf(elem) + 1];
        });

        setTimeout(() => {
          setIsProcessingKey(false);
        }, 300);
      }
      if (e.key === "Enter") {
        setSelectedElement((selectedEl) => {
          if (!selectedEl) return selectedEl;
          const [type, id] = selectedEl.split("-");
          if (type === "contract" || type === "featuredContract") {
            navigate(`/tv/contracts/${id}`);
          }
          if (type === "token") {
            // navigate(`/tv/tokens/${id}`);
            dispatch(toggleTokenVisibility({ id, uid: duid }));
            if (tokenFilter === "selected") {
              const nextElement =
                selectableElements[selectableElements.indexOf(selectedEl) + 1];

              return nextElement
                ? nextElement
                : selectableElements[
                    selectableElements.indexOf(selectedEl) - 1
                  ];
            }
          }
          if (type === "button" && id === "start") {
            dispatch(setPausedFromDevice(false));
            navigate(`/tv/slideshow`);
          }
          if (type === "button" && id === "settings") {
            navigate(`/tv/settings`);
          }
          if (type === "button" && id === "all") {
            setTokenFilter(null);
          }
          if (type === "button" && (id === "selected" || id === "featured")) {
            setTokenFilter(id);
          }
          if (type === "button" && id === "search") {
            setIsSearchVisible((isSearchVisible) => {
              return !isSearchVisible;
            });
          }
          if (type === "input" && id === "search") {
            searchInputRef.current.blur();
          }
          return selectableElements[selectableElements.indexOf(selectedEl)];
        });
        setTimeout(() => {
          setIsProcessingKey(false);
        }, 300);
      }

      // console.log(e.key);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectableElements, featuredContracts, contracts, tokens]);

  useEffect(() => {
    if (isSearchVisible && searchInputRef?.current) {
      searchInputRef.current.focus();
      setSelectedElement("input-search");
    }
  }, [isSearchVisible]);

  useEffect(() => {
    if (
      selectedElement !== "input-search" &&
      document.activeElement === searchInputRef.current
    ) {
      searchInputRef.current.blur();
    } else if (
      selectedElement === "input-search" &&
      document.activeElement !== searchInputRef.current
    ) {
      searchInputRef.current.focus();
    }
  }, [selectedElement]);

  const handleSearchQuery = (query) => {
    setSearchQuery(query);
    dispatch(listTokens({ search: query, uid: duid }));
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 8000, min: 3000 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      partialVisibilityGutter: 20,
    },
  };

  const featuredResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 8000, min: 3000 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 20,
    },
  };

  return (
    <div className="tv-main-wrapper TvWalletDashboard">
      <Container>
        <div className="tv-dashboard-header row">
          <div className="col-6 mb-5">
            <h1 className="mb-0">
              <img
                width="7052"
                height="1080"
                alt="SAMSUNG"
                src={logo}
                style={{ height: "1.5625vw", width: "auto" }}
              />
            </h1>
            <span className="h1 mt-0 text-muted fs-32">
              <strong>NFT Gallery</strong>
            </span>
          </div>
          <div className="col-6 text-end">
            <Button
              className="me-5 rounded-pill"
              color={
                selectedElement === "button-start" ? "primary" : "secondary"
              }
              onClick={() => navigate("/tv/slideshow")}
              size="lg"
              id="selector-button-start"
            >
              Start Slideshow
            </Button>
            <Button
              className="me-5 rounded-pill"
              color={
                selectedElement === "button-settings" ? "primary" : "secondary"
              }
              onClick={() => navigate("/tv/settings")}
              size="lg"
              id="selector-button-settings"
            >
              <FaCog />
            </Button>
          </div>
        </div>
      </Container>
      <Container>
        <h2 className="h4 mt-48 text-muted fs-56">Featured Collections</h2>
        <div
          id="selector-featuredContract-row"
          style={{ marginLeft: "-0.625vw" }}
        >
          {contracts?.map && (
            <Carousel
              responsive={featuredResponsive}
              ref={featuredContractsCarouselRef}
              keyBoardControl={false}
              swipeable={false}
              draggable={false}
              arrows={false}
              partialVisible={true}
            >
              {featuredContracts?.map((contract) => (
                <div
                  key={contract}
                  id={`selector-featuredContract-${contract}`}
                  className={
                    selectedElement === `featuredContract-${contract}`
                      ? "selected-card mx-24"
                      : "mx-24"
                  }
                >
                  <ContractCard
                    contractId={contract}
                    selected={
                      selectedElement === `featuredContract-${contract}`
                    }
                    onTvDevice
                    featuredCollection
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>

        <h2 className="h4 mt-48 text-muted fs-56">Your collections</h2>
        <div id="selector-contract-row" style={{ marginLeft: "-0.625vw" }}>
          {contracts?.map && (
            <Carousel
              responsive={responsive}
              ref={contractsCarouselRef}
              keyBoardControl={false}
              swipeable={false}
              draggable={false}
              arrows={false}
              partialVisible={true}
            >
              {contracts?.map((contract) => (
                <div
                  key={contract}
                  id={`selector-contract-${contract}`}
                  className={
                    selectedElement === `contract-${contract}`
                      ? "selected-card mx-24"
                      : "mx-24"
                  }
                >
                  <ContractCard
                    contractId={contract}
                    selected={selectedElement === `contract-${contract}`}
                    onTvDevice
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
        <div className="d-flex justify-content-between align-items-center my-4 mx-1">
          {!isSearchVisible && (
            <h2 className="h4 mt-48 text-muted fs-56">Your Tokens</h2>
          )}
          {isSearchVisible && (
            <input
              ref={searchInputRef}
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchQuery(e.target.value)}
              style={{ backgroundColor: "transparent", width: "50%" }}
              className="align-self-start form-control fs-56"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  searchInputRef?.current?.blur();
                }
              }}
            />
          )}
          <p className="h1 align-self-end fs-48">
            <span
              className={classNames(
                "fs-48 rounded-pill px-24 ms-24 btn",
                !isSearchVisible && tokenFilter === "selected"
                  ? "border border-primary border-1"
                  : "",
                selectedElement === "button-selected" &&
                  " btn-primary selected-text",
                {
                  "text-muted": selectedElement !== "button-selected",
                }
              )}
              id={`selector-button-selected`}
            >
              Selected
            </span>{" "}
            <span
              className={classNames(
                "fs-48 rounded-pill px-24 ms-24 btn",
                !isSearchVisible && tokenFilter === "featured"
                  ? "border border-primary border-1"
                  : "",
                selectedElement === "button-featured" &&
                  "btn-primary selected-text",
                {
                  "text-muted": selectedElement !== "button-featured",
                }
              )}
              id={`selector-button-featured`}
            >
              Featured
            </span>{" "}
            <span
              // onClick={() => dispatch(setTokensVisibility({ owned: true }))}
              id={`selector-button-all`}
              className={classNames(
                "fs-48 rounded-pill px-24 ms-24 btn",
                !isSearchVisible && !tokenFilter
                  ? "border border-primary border-1"
                  : "",
                selectedElement === "button-all" && "btn-primary selected-text",
                {
                  "text-muted": selectedElement !== "button-all",
                }
              )}
            >
              All
            </span>
            <span className="ms-24"> | </span>
            {!isSearchVisible && (
              <span
                className={classNames(
                  "btn fs-48 rounded-pill px-24 ms-24",
                  selectedElement === "button-search" &&
                    "btn-primary selected-text",
                  {
                    "text-muted":
                      tokenFilter && selectedElement !== "button-search",
                  }
                )}
                onClick={() => setIsSearchVisible(!isSearchVisible)}
              >
                <MdOutlineSearch id={`selector-button-search`} />
              </span>
            )}
            {isSearchVisible && (
              <span
                onClick={() => setIsSearchVisible(!isSearchVisible)}
                className={classNames(
                  "btn fs-48 rounded-pill px-24 ms-24",
                  "border border-primary border-1",
                  selectedElement === "button-search" &&
                    "btn-primary selected-text",
                  {
                    "text-muted":
                      tokenFilter && selectedElement !== "button-search",
                  }
                )}
              >
                <MdOutlineSearchOff id={`selector-button-search`} />
              </span>
            )}
          </p>
        </div>
        <Row style={{ marginLeft: "-0.625vw" }}>
          {tokens?.length ? (
            tokens?.map((token) => (
              <Col
                xs={4}
                md={2}
                key={token}
                id={`selector-token-${token}`}
                className={
                  selectedElement === `token-${token}`
                    ? "selected-card px-24"
                    : "px-24"
                }
              >
                <TokenCard
                  tokenId={token}
                  selected={selectedElement === `token-${token}`}
                  onTvDevice
                />
              </Col>
            ))
          ) : (
            <Col xs={{ size: 8, offset: 2 }}>
              <div className="py-120 text-center">
                {tokenFilter === "selected" && !isSearchVisible ? (
                  <>
                    <h1 className="mb-0">
                      <img
                        width="7052"
                        height="1080"
                        alt="SAMSUNG"
                        src={logo}
                        style={{ height: "1.5625vw", width: "auto" }}
                      />
                    </h1>
                    <span className="h1 mt-0 text-muted fs-32">
                      <strong>NFT Gallery</strong>
                    </span>
                    <p className="my-48 fs-56 text-center py-120">
                      Welcome to the Samsung NFT Gallery App. Your slideshow is
                      currently empty.
                      <br />
                      Dive into your Collections and Tokens to select your
                      favourites.
                    </p>
                  </>
                ) : (
                  <p className="my-48 fs-56 text-center py-120">
                    You do not have any tokens for this filter.
                  </p>
                )}
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default TvWalletDashboard;
