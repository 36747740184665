import React from "react";
import { Container, Spinner } from "reactstrap";
import logo from "../assets/icons/Samsung_wordmark_white.svg";

const LoadingScreen = () => {
  return (
    <div className="tv-main-wrapper TvLoadingScreen">
      <Container>
        <div className="tv-dashboard-header row" style={{ overflow: "hidden" }}>
          <div style={{ marginTop: "27vh", marginLeft: "50vw" }}>
            <div className="my-3">
              <Spinner />
            </div>
            <h1 className="mb-0" style={{ marginLeft: "-3.75vw" }}>
              <img
                width="7052"
                height="1080"
                alt="SAMSUNG"
                src={logo}
                style={{ height: "1.5625vw", width: "auto" }}
              />
            </h1>
            <span
              className="h1 mt-0 text-muted fs-32"
              style={{ marginLeft: "-3.75vw" }}
            >
              <strong>NFT Gallery</strong>
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LoadingScreen;
