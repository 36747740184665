import PropTypes from "prop-types";
import * as React from "react";

const FallbackBlankIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}></svg>
  );
};

FallbackBlankIcon.propTypes = {
  size: PropTypes.number,
};

FallbackBlankIcon.defaultProps = {
  size: 16,
};

export default FallbackBlankIcon;
