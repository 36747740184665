/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

// What to do for a new resource?
// 1.) add Import
// import {actions as resourceNameActions} from './resourceName';
// 2.) add store included handlinge
// } else if (entry.type === "resource_name"){
//   return put({type: resourceNameActions.addToDictionary, payload: entry});
// }

import { all, put, takeEvery } from "redux-saga/effects";
import { actions as contractsActions } from "./contracts";
import { actions as tokensActions } from "./tokens";
import { actions as featuredTokensActions } from "./featuredTokens";

/** Worker Sagas */
export function* storeIncluded(action) {
  yield all(
    action?.payload?.map((entry) => {
      if (entry.type === "contract") {
        return put(contractsActions.addToDictionary(entry));
      }
      if (entry.type === "token") {
        return put(tokensActions.addToDictionary(entry));
      }
      if (entry.type === "featuredToken") {
        return put(featuredTokensActions.addToDictionary(entry));
      }
      // else return null;
      return null;
    })
  );
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* dictionarySagas() {
  yield takeEvery("dictionary/storeIncluded", storeIncluded);
}
export default dictionarySagas;
