import React, { useEffect, useState } from "react";
import ImageLoader from "./media.worker";

const WebWorkerImage = ({ className, src, alt, onLoad, onError, style }) => {
  // const [result, setResult] = useState(null);
  // useEffect(() => {
  //   // Create a new web worker
  //   const myWorker = new ImageLoader();
  //   // Set up event listener for messages from the worker
  //   myWorker.onmessage = function (event) {
  //     // console.log("Received result from worker:", event.data, src);
  //     if (event.data.status === "success") {
  //       setResult(event.data.blob);
  //     } else {
  //       setResult(event.data.src);
  //     }
  //   };
  //   myWorker.postMessage(src);
  //   // Clean up the worker when the component unmounts
  //   return () => {
  //     myWorker?.terminate();
  //   };
  // }, []); // Run this effect only once when the component mounts

  let result = src;
  if (result?.startsWith("ipfs://")) {
    result = result.replace("ipfs://", "https://ipfs.io/ipfs/");
  }

  return (
    <>
      {result && (
        <img
          className={className}
          style={style}
          src={result}
          alt={alt}
          onError={onError}
          onLoad={onLoad}
        />
      )}
    </>
  );
};

export default WebWorkerImage;
