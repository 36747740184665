// import { injected } from "./utils/Web3Connectors";
import React from "react";
// import { Card, CardBody, CardTitle } from "reactstrap";
import { Button } from "reactstrap";
import { walletConnectV2 } from "../../connectors/walletConnect";
import { WalletConnectIcon } from "../../assets/icons";

const WalletConnectButton = (props) => {
  //props from web 3 must be instantiated wherever function is used

  // useEffect(() => {
  //   void metaMask.connectEagerly()
  //   .then(()=> {
  //     console.debug("Connected eagerly to metamask");
  //   })
  //   .catch(() => {
  //     console.debug("Failed to connect eagerly to metamask");
  //   });
  // }, []);

  const handleConnect = () => {
    walletConnectV2
      .activate(1)
      .then(() => {
        console.log("connected");
      })
      .catch((err) => console.log(err));
  };

  // useEffect(() => {
  //   handleConnect();
  // }, []);

  return (
    <Button
      onClick={() => handleConnect()}
      color="transparent"
      className={`text-start py-2 mb-3 fw-bold nav-button border-0 rounded-0 ps-lg-5 ${
        false && "active"
      }`}
      block
    >
      <WalletConnectIcon width={32} className="me-4" />
      <span className="h5 fw-bold">{"Wallet Connect"}</span>
    </Button>
  );
};

export default WalletConnectButton;
