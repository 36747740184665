import { initializeConnector } from "@web3-react/core";
import { LedgerWallet } from "./lib/ledgerWallet";
import { MAINNET_CHAINS, TESTNET_CHAINS } from "./chains";

export const [ledgerWallet, hooks] = initializeConnector(
  (actions) =>
    new LedgerWallet({
      actions,
      options: {
        rpc: process.env.REACT_APP_INFURA_API_KEY
          ? {
              1:
                MAINNET_CHAINS[1]?.urls?.[0] ||
                `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`,
              5:
                TESTNET_CHAINS[5]?.urls?.[0] ||
                `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`,
              137: MAINNET_CHAINS[137]?.urls?.[0] || `https://polygon-rpc.com`,
              11155111:
                TESTNET_CHAINS[11155111]?.urls?.[0] ||
                `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`,
            }
          : {},
        chainId: 1,
        chainHex: "0x1",
      },
    })
);
