import React, { useEffect } from "react";
import logo from "../assets/icons/logo.png";
import { useWeb3React } from "@web3-react/core";
import ReactCodeInput from "react-code-input";
import {
  createWallet,
  getWalletByAddress,
  prepareWalletSiwe,
  walletSiwe,
} from "../app/wallets";
import { useDispatch, useSelector } from "react-redux";
import { listTokens, setTokensListMode } from "../app/tokens";
import { Button, Nav, NavItem, Navbar, NavbarBrand } from "reactstrap";
import ConnectWalletSection from "../components/ConnectWallet/ConnectWalletSection";
import { Outlet, useNavigate, Link, useSearchParams } from "react-router-dom";
import { device, pairDevice, showDeviceWithPairingToken } from "../app/devices";
import { listContracts } from "../app/contracts";
import { FaCheck } from "react-icons/fa6";

const Root = () => {
  const dispatch = useDispatch();
  const deviceData = useSelector(device);
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const queryCode = searchParams.get("code");
  const walletProvider = searchParams.get("wallet");
  const isWalletBrowser = !!queryCode && !!walletProvider;
  const [code, setCode] = React.useState(queryCode || "");
  const { account, provider, connector, chainId } = useWeb3React();
  const tokenListMode = useSelector((state) => state.tokens.listMode);
  const currentWalletId = useSelector((state) => state.wallets.currentWalletId);
  const pairingStatus = deviceData?.attributes?.pairing_status || "unpaired";
  const unpaired = pairingStatus === "unpaired";
  // const paired = pairingStatus === "paired";
  const pairing = pairingStatus === "pairing";

  const searchParamPairingToken = searchParams.get("pairing_token");

  // useEffect(() => {
  //   if (isWalletBrowser) {
  //     handlePairDevice();
  //   }
  // }, [isWalletBrowser]);

  useEffect(() => {
    if (searchParamPairingToken && !deviceData) {
      dispatch(
        showDeviceWithPairingToken({ pairing_token: searchParamPairingToken })
      );
    }
  }, [searchParamPairingToken]);

  const wallet = useSelector((state) => getWalletByAddress(state, account));

  useEffect(() => {
    if (account) {
      dispatch(
        createWallet({
          account,
        })
      );
      // handleSiwe();
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (account && wallet && !currentWalletId) {
      handleSiwe();
    }
  }, [account, wallet, currentWalletId]);

  useEffect(() => {
    if (currentWalletId) {
      navigate("/wallet");
    }
  }, [currentWalletId, navigate]);

  const handlePairDevice = () => {
    dispatch(
      pairDevice({
        code,
        callbackSuccess: () => {
          localStorage.setItem("code", code);
        },
      })
    );
  };

  const handleSiwe = () => {
    dispatch(
      prepareWalletSiwe({
        provider,
        connector,
        chainId,
        account,
        nonce: wallet?.attributes.nonce,
        nonceIssuedAt: wallet?.attributes.nonce_issued_at,
        code: code || queryCode,
        callbackSuccess: ({ signature }) => {
          dispatch(
            walletSiwe({
              id: wallet.id,
              code: code || queryCode,
              signature,
              callbackSuccess: () => {
                dispatch(listTokens({ filter: "visible" }));
                dispatch(listContracts({ featured: true }));
              },
            })
          );
        },
      })
    );
  };
  return (
    <div className="App">
      <Navbar className="my-2" dark>
        <NavbarBrand href="/">
          <img src={logo} width={100} alt={"logo"} />
        </NavbarBrand>
        {currentWalletId && (
          <Nav>
            <NavItem>
              <Link
                to="/wallet"
                className="nav-link"
                active={tokenListMode === "card"}
                onClick={() => dispatch(setTokensListMode("card"))}
              >
                {" "}
                Gallery view
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/wallet"
                className="nav-link"
                active={tokenListMode === "list"}
                onClick={() => dispatch(setTokensListMode("list"))}
              >
                {" "}
                List view
              </Link>
            </NavItem>
          </Nav>
        )}
      </Navbar>

      {!currentWalletId && (
        <div className="container">
          <header className="App-header">
            {unpaired && !isWalletBrowser && (
              <>
                <h1 className="text-start mx-2">
                  Welcome to the Samsung NFT Gallery.
                </h1>
                <p className="text-start mx-2">
                  Please enter the code shown on your samsung tv app
                </p>
              </>
            )}
            {unpaired && !isWalletBrowser && (
              <ReactCodeInput
                onChange={(val) => setCode(val.toUpperCase())}
                fields={4}
                value={code}
              />
            )}
            {unpaired && code && code.length === 4 && !isWalletBrowser && (
              <Button
                className="mt-3 rounded-pill px-4"
                onClick={handlePairDevice}
              >
                Connect
              </Button>
            )}
            {(pairing || isWalletBrowser) && (
              <>
                <p className="text-small">
                  <FaCheck /> Connected with Frame {code || queryCode}
                </p>
                <h1 className="text-start mx-2 my-5">
                  Please connect your Wallet:
                </h1>
                <ConnectWalletSection
                  handleSiwe={handleSiwe}
                  selectedProvider={walletProvider}
                />
              </>
            )}
            {/* {pairing && account && (
            <div>
              <p>Connected to wallet {account}</p>
              <Button onClick={handleSiwe}>Sign In</Button>
            </div>
          )} */}
          </header>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default Root;
