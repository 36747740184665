import PropTypes from "prop-types";
import * as React from "react";

const BowlineIcon = ({ width, height, className }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M2360 5114 c-398 -36 -784 -159 -1103 -349 -680 -407 -1112 -1056
-1234 -1852 -23 -154 -24 -546 0 -703 86 -572 333 -1070 726 -1460 403 -400
880 -637 1461 -727 155 -24 546 -24 703 0 697 107 1285 454 1698 1003 347 462
528 1042 506 1624 -25 665 -289 1267 -756 1730 -393 388 -887 633 -1443 716
-99 14 -466 26 -558 18z m-250 -1110 c56 -31 89 -67 117 -130 17 -36 18 -113
23 -1158 l5 -1120 33 -19 c165 -97 446 -102 598 -11 123 74 192 167 236 319
20 71 23 101 23 270 0 170 -3 199 -24 272 -69 244 -250 373 -524 373 -98 0
-178 48 -206 123 -15 39 -14 121 2 160 19 45 89 104 134 114 21 4 119 8 218 7
166 0 187 -2 270 -28 355 -108 565 -341 646 -717 32 -148 33 -458 2 -599 -48
-220 -138 -388 -276 -516 -199 -185 -487 -275 -837 -261 -368 16 -707 162
-797 345 l-28 57 0 1180 0 1180 30 54 c16 29 45 66 65 82 77 65 196 74 290 23z"
        />
      </g>
    </svg>
  );
};

BowlineIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
};

BowlineIcon.defaultProps = {
  height: "512.000000pt",
};

export default BowlineIcon;
