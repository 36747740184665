import React, { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { getWalletAttributes } from "../app/wallets";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { listTokens } from "../app/tokens";

const FinalScreen = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentWalletId = useSelector((state) => state.wallets.currentWalletId);
  const currentWallet = useSelector((state) =>
    getWalletAttributes(state, currentWalletId)
  );
  const tokens = useSelector((state) => state.tokens.idsList);

  useEffect(() => {
    dispatch(listTokens({ filter: "visible" }));
  }, []);

  return (
    <div className="FinalScreen">
      {account && currentWallet && (
        <div className="container">
          <header className="App-header">
            <h1 className="text-start mx-2">
              {tokens.length
                ? "Enjoy your Slideshow on our TV!"
                : "No tokens selected."}
            </h1>
            <p className="text-start mx-2">
              {tokens.length
                ? "You can always come back scanning the QR Code " +
                  "from your Samsung Smart Tv or add this App to the Home Screen."
                : "The slideshow is currently empty. To select tokens, go back to " +
                  "the dashboard and select tokens."}
            </p>
            <Button
              color="primary"
              onClick={() => {
                navigate("/wallet");
              }}
              className="rounded-pill w-100 mt-4"
            >
              Back
            </Button>
          </header>
        </div>
      )}
    </div>
  );
};

export default FinalScreen;
