/**
 * The Home container
 */
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getContractAttributes,
  getContractRelationships,
} from "../app/contracts";
import { useWeb3React } from "@web3-react/core";
import { AccordionBody, AccordionHeader, AccordionItem, Col, Row } from "reactstrap";
import TokenCard from "./TokenCard";
import { MdVerified } from "react-icons/md";

/**
 * Home container used as app default landing page
 */
const ContractAccordionItem = (props) => {
  const { contractId } = props;
  const { account } = useWeb3React();
  const contractData = useSelector((state) =>
    getContractAttributes(state, contractId)
  );
  const relationships = useSelector((state) =>
    getContractRelationships(state, contractId)
  );
  const tokenIds = relationships?.tokens?.data?.map((token) => token.id);
  console.log("relationships", relationships);

  const dispatch = useDispatch();
  console.log(dispatch, account);

  return (
    <AccordionItem key={contractId}>
      <AccordionHeader targetId={contractId}>
        {contractData?.name} ({contractData?.tokens_count}) {contractData?.verified && <MdVerified className="mx-1"/>}
      </AccordionHeader>
      <AccordionBody accordionId={contractId}>
        <Row>
          {tokenIds.map((tokenId) => (
            <Col xs={4} md={3} key={tokenId}>
              <TokenCard tokenId={tokenId} />
            </Col>
          ))}
        </Row>
      </AccordionBody>
    </AccordionItem>
  );
};

ContractAccordionItem.propTypes = {
  contractId: PropTypes.string.isRequired,
};

/**  define proptype for the 'translation' function  */
ContractAccordionItem.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default ContractAccordionItem;
