/**
 * The Home container
 */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getContractAttributes } from "../app/contracts";
import { Card, CardBody, Spinner, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import placeholder from "../assets/icons/placeholder.svg";
import WebWorkerImage from "./WebWorkerImage";
import WebWorkerVideo from "./WebWorkerVideo";

/**
 * Home container used as app default landing page
 */
const ContractCard = (props) => {
  const videoRef = React.useRef(null);
  const { contractId, onTvDevice, selected, featuredCollection } = props;
  const contractData = useSelector((state) =>
    getContractAttributes(state, contractId)
  );
  const [imgLoaded, setImgLoaded] = React.useState(false);

  const playVideo = () => {
    if (!videoRef?.current?.play) return;
    videoRef.current.play();
  };

  const pauseVideo = () => {
    if (!videoRef?.current?.pause) return;
    videoRef.current.pause();
  };

  useEffect(() => {
    console.log("selected", selected);
    if (selected) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [selected]);

  return (
    <Card
      className="contractcard mb-3 border-0 "
      color={selected ? "primary" : "secondary"}
    >
      <div
        style={{
          overflow: "hidden",
          position: "relative",
        }}
        className={
          onTvDevice && featuredCollection
            ? "ratio ratio-11x4"
            : "ratio ratio-4x3"
        }
      >
        {contractData?.thumbnail ? (
          <>
            {selected && contractData?.thumbnail?.type === "video" && (
              <WebWorkerVideo
                ref={videoRef}
                src={contractData?.thumbnail?.url}
                className="object-fit-cover h-100 w-100"
                autoPlay
                loop
                muted
              />
            )}
            {contractData?.thumbnail?.type === "image" && (
              <>
                {" "}
                {!imgLoaded && (
                  <div
                    style={{
                      position: "absolute",
                      left: "43%",
                      top: "43%",
                      zIndex: "9999",
                    }}
                  >
                    <Spinner color="light">Loading...</Spinner>
                  </div>
                )}
                <WebWorkerImage
                  className="card-img-top object-fit-cover h-100 w-100 bg-dark"
                  style={{
                    position: onTvDevice ? "absolute" : undefined,
                    margin: "auto auto",
                  }}
                  src={contractData?.thumbnail?.url}
                  alt={contractData?.name}
                  onError={(event) => {
                    event.target.src = placeholder;
                    event.onerror = null;
                    setImgLoaded(true);
                  }}
                  onLoad={(event) => {
                    setImgLoaded(true);
                  }}
                />
              </>
            )}
          </>
        ) : (
          <div className="bg-primary ratio ratio-1x1 card-img-top" />
        )}
      </div>
      <CardBody className="contractcard__body overflow-hidden">
        <CardTitle className="mb-0">
          <Link
            to={
              onTvDevice
                ? `/tv/contracts/${contractId}`
                : `/contracts/${contractId}`
            }
            className={`text-decoration-none ${
              !selected ? "text-body" : "text-dark"
            }`}
          >
            <div style={{ height: onTvDevice ? "1.5em" : "3em" }}>
              {contractData?.name}
              {contractData?.verified && <MdVerified className="mx-1" />}
            </div>
          </Link>
        </CardTitle>
      </CardBody>
    </Card>
  );
};

ContractCard.propTypes = {
  contractId: PropTypes.string.isRequired,
  onTvDevice: PropTypes.bool,
  featuredCollection: PropTypes.bool,
};

/** export the component as redux connected component */
export default ContractCard;
