import React, { useEffect, useState } from "react";
import VideoLoader from "./media.worker";
import { Spinner } from "reactstrap";
import { FaPlay } from "react-icons/fa6";
import WebWorkerImage from "./WebWorkerImage";
import ReactPlayer from "react-player";

const WebWorkerVideo = ({
  className,
  src,
  autoPlay,
  loop,
  muted,
  poster,
  style,
  onError,
  onLoadStart,
  onCanPlay,
  onCanPlayThrough,
  precacheWebworker,
  viaWebworker,
  slideShowMode,
  active,
  onTvDevice,
}) => {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [debugProgress, setDebugProgress] = useState({});
  const [debugDuration, setDebugDuration] = useState(false);
  const [debugReady, setDebugReady] = useState(false);
  const [debugError, setDebugError] = useState(false);
  const [debugWebworker, setDebugWebworker] = useState(false);
  const [mediaUnsupported, setMediaUnsupported] = useState(false);

  // const [result, setResult] = useState(false);
  const videoRef = React.useRef(null);
  useEffect(() => {
    if (result) return; // If result exists, return and do not load again
    if (precacheWebworker || (viaWebworker && active)) {
      // Create a new web worker
      const myWorker = new VideoLoader();
      // Set up event listener for messages from the worker
      setDebugWebworker("Loading...");
      myWorker.onmessage = function (event) {
        // console.log("Received result from worker:", event.data);
        setDebugWebworker("Received Result... " + event.data.status);
        if (event.data.status === "success") {
          setResult(event.data.blob);
        } else {
          setResult(event.data.src);
        }
      };
      myWorker.postMessage(`/get_asset?url=${src}`);
      // Clean up the worker when the component unmounts
      return () => {
        myWorker?.terminate();
      };
    } else if (!viaWebworker) {
      let inlineResult = src;
      if (inlineResult.startsWith("ipfs://")) {
        inlineResult = inlineResult.replace("ipfs://", "https://ipfs.io/ipfs/");
      }
      setResult(`${inlineResult}`);
    }
  }, [active]); // Run this effect only once when the component mounts

  return (
    <div style={{ backgroundColor: "#000000", height: "100%", width: "100%" }}>
      {/* <pre
        style={{
          color: "#ffffff",
          position: "absolute",
          background: "rgba(0,0,0,0.3)",
          left: "5%",
          top: "5%",
          width: "90%",
          fontSize: "0.6rem",
          zIndex: "9999",
          whiteSpace: "pre-wrap",
        }}
      >
        error: {JSON.stringify(debugError) || debugError}
        <br />
        debugWebworker: {debugWebworker}
        <br />
        isLoading: {isLoading ? "Yes" : "No"}
        <br />
        isPlaying: {isPlaying ? "Yes" : "No"}
        <br />
        ready: {debugReady ? "Yes" : "No"}
        <br />
        result: {result}
        <br />
        duration: {debugDuration}
        <br />
        progress: {JSON.stringify(debugProgress)}
        <br />
        active: {active ? "Yes" : "No"}
        <br />
        precacheWebworker: {precacheWebworker ? "Yes" : "No"}
        <br />
        viaWebworker: {viaWebworker ? "Yes" : "No"}
      </pre> */}
      {(!result || isLoading) && active && (
        <div
          style={{
            position: "absolute",
            left: "43%",
            top: "43%",
            zIndex: "9999",
          }}
        >
          <Spinner color="light">Loading...</Spinner>
        </div>
      )}
      {mediaUnsupported && (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            zIndex: "9999",
            width: "100%",
            textAlign: "center",
            color: "red",
            background: "rgba(255,255,255,0.8)",
          }}
        >
          Sorry, this token can't be displayed currently.
        </div>
      )}
      {(isLoading || !isPlaying || !active || mediaUnsupported) && poster && (
        <WebWorkerImage
          className={
            slideShowMode
              ? "object-fit-contain h-100 w-100"
              : "card-img-top object-fit-cover h-100 w-100 bg-dark video-fallback"
          }
          style={
            slideShowMode
              ? { position: "fixed", top: 0, left: 0, zIndex: 100 }
              : {
                  position: onTvDevice ? "absolute" : undefined,
                  margin: "auto auto",
                  backgroundColor: "#000",
                }
          }
          src={poster}
        />
      )}
      {(isLoading || !isPlaying || !active) && !poster && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "5em",
            height: "100%", // Add this line to make the div take up the full height
          }}
        >
          <FaPlay />
        </div>
      )}

      {(active || precacheWebworker) && result && !mediaUnsupported && (
        <ReactPlayer
          forceVideo
          style={style}
          className={className}
          playing={active || autoPlay}
          loop={loop}
          muted={muted}
          playsinline
          // onLoadStart={() => {
          //   setIsLoading(true);
          //   // setTimeout(() => videoRef?.current?.play(), 500);
          //   if (onLoadStart) onLoadStart();
          // }}
          // onCanPlayThrough={() => {
          //   setIsLoading(false);
          //   videoRef?.current?.play();
          //   if (onCanPlayThrough) onCanPlayThrough();
          // }}
          // onCanPlay={() => {
          //   setIsLoading(false);
          //   videoRef?.current?.play();
          //   if (onCanPlay) onCanPlay();
          // }}
          // onError={onError}
          onPlay={() => {
            setIsPlaying(true);
          }}
          onStart={() => {
            setIsPlaying(true);
          }}
          onPause={() => {
            setIsPlaying(false);
          }}
          onEnded={() => {
            setIsPlaying(false);
          }}
          onError={(event, data, hlsInstance, hlsGlobal) => {
            const err = event.target.error;
            console.error(
              "React Player Error: ",
              event,
              err,
              data,
              hlsInstance,
              hlsGlobal
            );
            setDebugError(err?.message || err?.code || "error");
            setIsPlaying(false);
            if (err?.code === 3 && (viaWebworker || precacheWebworker)) {
              let inlineResult = src;
              if (inlineResult.startsWith("ipfs://")) {
                inlineResult = inlineResult.replace(
                  "ipfs://",
                  "https://ipfs.io/ipfs/"
                );
              }
              setResult(`${inlineResult}`);
            }
            if (err?.code === 4) {
              setMediaUnsupported(true);
            }
          }}
          onProgress={(data) => setDebugProgress(data)}
          onDuration={(data) => setDebugDuration(data)}
          onReady={() => setDebugReady(true)}
          url={result}
        />
      )}
    </div>
  );
};

export default WebWorkerVideo;
