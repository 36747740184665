/*
Replace
resourceName with your resource Name :) Take care about different writings!
/!\ ResourceName resourceName ResourceNames resourceNames /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const featuredTokensSlice = createSlice({
  name: "featuredTokens",
  initialState: {
    ...defaultInitialState,
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    addToDictionary: defaultAddToDictionary,
  },
});
export const { list: listFeaturedTokens } = featuredTokensSlice.actions;

export const actions = featuredTokensSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const featuredTokens = (state) =>
  state.featuredTokens.idsList.map((id) => state.featuredTokens.dictionary[id]);

export const getFeaturedToken = createSelector(
  (state) => state.featuredTokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getFeaturedTokenAttributes = createSelector(
  (state) => state.featuredTokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getFeaturedTokenRelationships = createSelector(
  (state) => state.featuredTokens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export default featuredTokensSlice.reducer;
